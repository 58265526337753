import { useMsal } from "@azure/msal-react";
import React, { useState } from "react";
import { useLegacySupport } from "../../../components";
import { t } from "i18next";

const HeaderDropdown = (props: any) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { instance } = useMsal();
    const legacy = useLegacySupport();

    const __openDropdown = () => {
        setIsOpen(!isOpen);
    };

    const __logout = () => {
        try {
            if (legacy.isLegacySupported === false) {
                console.log("azure logout")
                window.localStorage.clear();

                const homeAccountId: any = instance.getActiveAccount()?.homeAccountId;
                const logoutRequest = {
                    account: instance.getAccountByHomeId(homeAccountId),
                    mainWindowRedirectUri: "/",
                    postLogoutRedirectUri: "/",
                };
                instance.logoutPopup(logoutRequest);
            } else {
                console.log("legacy logout")
                window.localStorage.clear();
                window.location.reload();
            }
        } catch (e) {
            console.log(e)
        }

    }

    return (
        <>
            <div className="filter-fields" >
                <div className={isOpen ? "select-container open" : "select-container"} >
                    <div className="select-button-wrapper">
                        <button onClick={__openDropdown} type="button">
                            {props.label || t("...")}
                        </button>
                    </div>

                    {isOpen && (
                        <div className="ul-wrapper">
                        <ul className="dropdown-list" style={{ height: "50px" }}>
                            <li className="item">
                                <button onClick={__logout} type="button">
                                    {t("LOG_OUT")}
                                </button>
                            </li>
                        </ul>
                        </div>
                    )}
                </div>
            </div>

            {isOpen && (
                <div
                    onClick={__openDropdown}
                    style={{
                        position: "fixed",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 10000,
                    }}
                ></div>
            )}
        </>
    );
};

export default HeaderDropdown;
