interface InputFieldProps {
  title: string;
  name: string;
  type: string;
  placeholder: string;
  onChange: any;
  value: string;
  error: boolean;
}

const InputField: React.FC<InputFieldProps> = (props) => {
  return (
    <div className="input-field-group">
      <label>{props.title}:</label>
      <input
        name={props.name}
        value={props.value}
        type={props.type}
        placeholder={props.placeholder}
        required
        onChange={(e) => props.onChange(e)}
        autoComplete="off"
      />
      {props.error ? (
        <span className={props.error ? "invalid-feedback" : "valid-feedback"}>
          please enter the valid {props.title}
        </span>
      ) : null}
    </div>
  );
};

export default InputField;
