// Import required modules and configuration
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';

// Import the action types from the constants file
import * as types from "./types";
import { encodeFormData, getNewAxiosInstance } from "../../../utils";



/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchCaseConfigByDSPId(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        let apiEndPoint = `/ca/config/${res.payload.dspId}`;

        // Perform GET request to fetch case summary data
        const response: AxiosResponse =  yield call(ins.get, apiEndPoint);

        // Dispatch action to update state with received data
        yield put({
            type: types.COMMUNITY_ASSETS_CONFIG_RECEIVE,
            payload: response.data
        });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.COMMUNITY_ASSETS_CONFIG_FAILURE, payload: { error: e } });
    }
}


/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchMapCoOrdinates(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        const queryString = encodeFormData(res.payload.param);

        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, `/ca/map?${queryString}`);
     
        // Dispatch action to update state with received data
        yield put({
            type: types.COMMUNITY_ASSETS_RECEIVE,
            payload: response.data
        });
    } catch (e: any) {
        // Handle failure and update state
        yield put({ type: types.COMMUNITY_ASSETS_FAILURE, payload: { error: e } });
    }
}

/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchCommunityAssetsList(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        const queryString = encodeFormData(res.payload.param);

        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, `/ca/data?${queryString}`);

        // Dispatch action to update state with received data
        yield put({
            type: types.COMMUNITY_ASSETS_LIST_RECEIVE,
            payload: response.data
        });
    } catch (e: any) {
        // Handle failure and update state
        yield put({ type: types.COMMUNITY_ASSETS_LIST_FAILURE, payload: { error: e } });
    }
}


/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* makeShift(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        const queryString = encodeFormData(res.payload.param);

        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.post, res.payload.url, res.payload.param);

        // Dispatch action to update state with received data
        yield put({
            type: types.MAKE_SHIFT_RECEIVE,
            payload: response.data
        });
    } catch (e: any) {
        // Handle failure and update state
        yield put({ type: types.MAKE_SHIFT_FAILURE, payload: { error: e } });
    }
}


/**
 * The main saga watcher. Will fork all other sagas into the middleware.
 */
export default function* dashboardSVODCA() {
    yield takeLatest(types.COMMUNITY_ASSETS_CONFIG_REQUEST, fetchCaseConfigByDSPId);
    yield takeLatest(types.COMMUNITY_ASSETS_REQUEST, fetchMapCoOrdinates);
    yield takeLatest(types.COMMUNITY_ASSETS_LIST_REQUEST, fetchCommunityAssetsList);
    yield takeLatest(types.MAKE_SHIFT_REQUEST, makeShift);

}