const Button = (props: any) => {
  return (
    <button
      onClick={() => (props.onClick ? props.onClick() : null)}
      {...props}
    >{props.title}</button>
  );
};

export default Button;
