//internal js
//import CryptoJS from 'crypto-js';
// import { X_AUTHORIZATION } from "../config";

//constants for storage
export const USER_DATA = "USER_DATA";
export const GROUP_REF_SELETECTED = "GROUP_REF_SELETECTED";
export const TOKEN = "TOKEN";
export const LANGUAGE = "LANGUAGE";


//functions
export const getData = async (key: any) => {
    try {
        var value = {};
        const storedValue: any = await localStorage.getItem(key);
        if (typeof storedValue === 'undefined') {
            value = {};
        } else {
            value = JSON.parse(storedValue);
        }
        return value;
    } catch (error) {
        console.log("Storage", "getData() :: " + error);
    }
};


export const setAsyncData = async (key?: any, data?: any, callback?: any) => {
    try {
        await localStorage.setItem(key, JSON.stringify(data));

        if (callback !== null && typeof callback === "function") {
            callback();
        }
    } catch (error) {
        console.log("Storage", "setAsyncData() :: " + error);
    }
};

export const setData = async (key?: any, data?: any, callback?: any) => {

    try {
        //const encrypted_data = CryptoJS.AES.encrypt(JSON.stringify(data), X_AUTHORIZATION);
        // const encrypted_data = JSON.stringify(data);
        await localStorage.setItem(key, JSON.stringify(data));
        if (callback !== null && typeof callback === "function") {
            callback();
        }
    } catch (error) {
        console.log("Storage", "setData() new:: " + error);
    }
};

export const clearStorage = async () => {
    try {
        await localStorage.multiRemove([USER_DATA, TOKEN], () => {
            return true;
        })
    } catch (error) {
        console.log("Storage", "clearStorage() :: " + error);
        return false;
    }
}

export const clearParticularStorage = async (type: any) => {
    try {
        await localStorage.removeItem(type);
        return true;
    } catch (error) {
        console.log("Storage", "clearParticularStorage() :: " + error);
        return false;
    }
}