import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { closeIcon } from "../../../assets/icons";
import { Button } from "../../../components";
import moment from "moment";
import { infinityLoader } from "../../../config/Images";
import { t } from "i18next";
import SelectPicker from "./selectPicker";


// Functional component for Information Governance
const BulkActionPopup = (props: any) => {
    console.log('props', props);
    // State for storing the "involve case" data
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    // State for storing form data

    const formObj: any = {};
    props?.data?.popupForm?.controls?.forEach((element: any) => {
        formObj[element.name] = element?.value === undefined ? "" : element.name === "ContactDate" || element.name === "CommitmentWeWillStartDate" || element.name === "CommitmentYouWillStartDate" ? moment(element.value, 'MM/DD/YYYY h:mm:ss A').format('DD/MM/YYYY') : element.value;
    });

    const [formData, setFormData] = useState<any>(formObj);


    // useEffect(() => {
    //     setIsFetching(false);
    // }, [props.errorFlag]);

    // Function to call API and handle redirection
    const callApiAndRedirect = (e: any): void => {

        if (Object.values(formData).some(a => a !== '')) {
            setIsFetching(true);
            props.onSubmit("formSubmit", formData);
        } else {
            toast.error("Please select values");
        }
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // useEffect(() => {
    //     if (props.isFetching !== isFetching) {
    //         setIsFetching(props.isFetching);
    //     }
    // }, [props.isFetching]);

    useEffect(() => {
        if (!Object.values(formData).some(a => a !== '')) {
            setIsEdit(true);
        }
    }, []);

    // Function to render layout based on item type
    const __renderLayout = (items: any, index: any) => {
        let itemsToRender = [];
        switch (items.type) {
            
            case "selectpicker":
                const tempItem = { ...items, selectedCaseReference: props.selectedCaseReference, isEditable: true };
                itemsToRender.push(
                    <SelectPicker
                        formData={formData}
                        hidden={!items.visible}
                        token={props.token}
                        dspId={props.dspId}
                        items={tempItem}
                        handleChange={handleChange}
                    />
                );
                break;
            
            default:
                return null;
        }

        return itemsToRender;
    };

    return (
        props?.data?.popupForm?.allowAdd == true || !isEdit ?
            <div className="addoutcome-popup">
                <div
                    className="modal fade show"
                    id="exampleModal"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{props.title}</h5>
                                <button className="btn btn-transparent close" onClick={() => {
                                    props.onSubmit("", null);
                                }} >
                                    <img src={closeIcon} alt="close" />
                                </button>
                            </div>
                            <div className="modal-body">
                                {props?.data?.popupForm === undefined && <div style={{ textAlign: "center" }}><img src={infinityLoader} alt="loading" style={{ width: 40 }} /></div>}
                                <form
                                    className="information-governance-container"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        callApiAndRedirect(e);
                                    }}
                                >
                                    <div className="content-container">
                                        <div className="row">
                                            {props?.data?.popupForm?.controls?.map((items: any, index: any) => {
                                                return __renderLayout(items, index);
                                            })}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <Button
                                    key={""}
                                    title={"Cancel"}
                                    className="button cancel-button"
                                    type="button"
                                    onClick={() => props.onSubmit("", null)}

                                />
                                <Button
                                    key={""}
                                    title={isFetching ? t("REQUESTING") : props?.data?.popupForm?.controls?.find((item: any) => item.name === "saveButton")?.label || t("SAVE")}
                                    name={props?.data?.popupForm?.controls?.find((item: any) => item.name === "saveButton")?.name}
                                    className={
                                        "button save-button " +
                                        (isFetching ? "requesting btn-disable" : "")
                                    }
                                    type="submit"
                                    onClick={callApiAndRedirect}
                                    disabled={props.isEditable || isFetching}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null
    );
};

export default BulkActionPopup;
