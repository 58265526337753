import * as types from "../types";
import { setInitialState } from "../../../../store/InitialState";

const INITIAL_STATE = setInitialState({
    data: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    error: false
});

export default function (state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case types.RESET_PASSCODE_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case types.RESET_PASSCODE_RECEIVE:
            return {
                ...state,
                isFetching: false,
                isSuccess: true,
                data: action.payload
            };
        case types.RESET_PASSCODE_FAILURE:
            if (action.error && action.payload.name === "RequestError") {
                return {
                    ...state,
                    isError: true,
                    isFetching: false,
                    data: { message: "INTERNET_OFFLINE_MESSAGE" }
                };
            } else {
                return {
                    ...state,
                    isFetching: false,
                    isError: true,
                    isSuccess: false,
                    data: action.payload,
                };
            };
        case types.RESET_RESET_PASSCODE:
            return {
                ...state,
                isFetching: false,
                isError: false,
                data: null,
            };
        default:
            return {
                ...state
            }
    }
}