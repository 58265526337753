import { call, put, takeLatest } from "redux-saga/effects";
import axios, { AxiosResponse } from "axios";

import { ApiConstants } from "../../../config";
import * as types from "./types";
import { getAxiosInstanceWithoutToken } from "../../../utils/commonUtils";

// saga function for the login
function* fetchUsers(params: any) {
  try {

    const ins = getAxiosInstanceWithoutToken();

    const response: AxiosResponse = yield call(
      ins.post,
      `${ApiConstants.API_ENDPOINT}/login/login`,
      {userName: params.payload.userName, passWord: params.payload.passWord}
    );
    yield put({ type: types.LOGIN_RECEIVE, payload: response.data });
  } catch (e: any) {
    yield put({ type: types.LOGIN_FAILURE, payload: { error: e.message } });
  }
}

// saga function for the passcode validation
function* passCodeLogin(params: any) {
  try {
    const ins = getAxiosInstanceWithoutToken();

    const response: AxiosResponse = yield call(
      ins.post,
      `${ApiConstants.API_ENDPOINT}/login/validate-passcode`,
      { token:params.payload.userToken,secondFactor:params.payload.secondaryAuthenticationPart}
    );
    
    yield put({ type: types.PASS_CODE_RECEIVE, payload: response.data });
  } catch (e: any) {
    yield put({ type: types.PASS_CODE_FAILURE, payload: { error: e.message } });
  }
}


// saga function for the passcode validation
function* resetPassword(params: any) {
  try {
    const ins = getAxiosInstanceWithoutToken();

    const response: AxiosResponse = yield call(
      ins.post,
      `${ApiConstants.API_ENDPOINT}/login/reset-password`, params
    );

    yield put({ type: types.RESET_PASSWORD_RECEIVE, payload: response.data });
  } catch (e: any) {
    yield put({ type: types.RESET_PASSWORD_FAILURE, payload: { error: e.message } });
  }
}


// saga function for the passcode validation
function* resetPasscode(params: any) {
  try {
    const ins = getAxiosInstanceWithoutToken();

    const response: AxiosResponse = yield call(
      ins.post,
      `${ApiConstants.API_ENDPOINT}/login/reset-password`,
      { token: params.payload.userToken, secondFactor: params.payload.secondaryAuthenticationPart }
    );

    yield put({ type: types.RESET_PASSCODE_RECEIVE, payload: response.data });
  } catch (e: any) {
    yield put({ type: types.RESET_PASSCODE_FAILURE, payload: { error: e.message } });
  }
}


export default function* fetchUsersSaga() {
  yield takeLatest(types.LOGIN_REQUEST, fetchUsers);
  yield takeLatest(types.PASS_CODE_REQUEST, passCodeLogin);
  yield takeLatest(types.RESET_PASSWORD_REQUEST, resetPassword);
  yield takeLatest(types.RESET_PASSCODE_REQUEST, resetPasscode);
}
