// Import the necessary methods from redux-saga/effects for side effects and parallelization
import { all, fork } from "redux-saga/effects";

// Import Saga file for the 'case-summary-one-view' view; you can import other view-specific sagas here
// import fetchSummaryOneViewSaga from "../../views/case-summary-one-view/store/saga";
import fetchSummaryOneViewSagaCM from "../../views/case-summary/store/saga";
import dashboardSVOD from "../../views/dashboard/store/saga";
import fetchUsersSaga from "../../views/auth-msal/store/saga";
import dashboardSVODCA from "../../views/community-assets/store/saga";

// The rootSaga is a generator function that uses the `all` effect to run multiple sagas in parallel
function* rootSaga() {
    // `all` takes an array of sagas to be executed in parallel
    // `fork` is a non-blocking call that starts the provided saga
    yield all([
        // fork(fetchSummaryOneViewSaga),
        fork(fetchSummaryOneViewSagaCM),
        fork(dashboardSVOD),
        fork(fetchUsersSaga), // Include the saga for the 'homepage' view
        // Add other view-specific sagas here, forked similarly
        fork(dashboardSVODCA), // Include the saga for the
    ])
}

// Export the rootSaga for use in the Redux store configuration
export default rootSaga;