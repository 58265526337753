// Importing React hooks
import { useEffect, useState } from "react";

/**
 * Summary React component that receives data via props and displays a summary list.
 *
 * @param {any} props - Data received from a parent component.
 * @returns {JSX.Element | null} - Rendered component or null.
 */
const Summary: any = (props: any) => {
    // State to hold the data for rendering
    const [data, setData] = useState<any>(null);

    // useEffect hook to update state when props change
    useEffect(() => {
        // Check if props.data is not null and update the state
        if (props?.data !== null) {
            setData(props?.data?.summary);
        }
    }, [props]);

    // Conditional rendering: if data is null, undefined or headerText is empty, render nothing
    if (data === null || data === undefined || data?.headerText === "") {
        return null;
    }

    /**
     * Helper function to render the summary list.
     *
     * @param {any} data - Data to be rendered as a list.
     * @returns {JSX.Element[]} - An array of JSX elements.
     */
    const __renderSummaryList = (data: any) => {
        if(data === undefined || data === null) {
            return null;
        }

        return data.map((res: any, index: number) => {
            return <div key={index}>
                <p>{res}</p>
            </div>
        });
    };

    /**
     * Helper function to render the summary list.
     *
     * @param {any} data - Data to be rendered as a list.
     * @returns {JSX.Element[]} - An array of JSX elements.
     */
    const __renderSummaryValue = (data: any) => {
        if (data === undefined || data === null) {
            return null;
        }

        return <p>{data}</p>
    };

    if (data === null || data === undefined || data?.value === "") {
        return null;
    }

    // Main component render
    return (
        <div className="pb-4 summary">
            <h5>{data?.header}</h5>
            {__renderSummaryValue(data?.value)}

            {/* Render the list by calling __renderSummaryList method */}
            {__renderSummaryList(data?.items)}

        </div>
    );
};

// Export the component
export default Summary;