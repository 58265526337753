// Import React hooks and Kendo Grid components
import { Grid, GridColumn, GridEvent, GridPageChangeEvent, GridRow, GridToolbar } from "@progress/kendo-react-grid"
import { useEffect, useState } from "react";
import { parseDataByType } from "../../../utils";

// Interface to type-check pagination states
interface PageState {
    skip: number,
    take: number
}

// Initial pagination state
const initialDataState: PageState = { skip: 0, take: 10 };

/**
 * KendoGrid component to handle displaying grid data.
 *
 * @param {any} props - The props passed from the parent component.
 * @returns {JSX.Element} - The rendered component.
 */
const KendoGrid: any = (props: any) => {
    const minVisibleColumnCount = 5;


    // State management
    const [gridData, setGridData] = useState<any>(null);
    const [isPagginated, setIsPaginated] = useState<any>(null);
    const [scrollable, setScrollable] = useState<any>(initialDataState.take > 10 ? "scrollable" : "none");
    const [isHiddenColumn, setIsHiddenColumn] = useState<boolean>(false);
    const [hidenColumnCount, setHidenColumnCount] = useState<number>(0);

    const [page, setPage] = useState<PageState>(initialDataState);
    const [pageSize, setPageSize] = useState<number>(10);

    // Event handler for pagination change
    const pageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
        event.page.take > 10 ? setScrollable("scrollable") : setScrollable("none");
    }

    // Data processing useEffect
    useEffect(() => {

        if (props.caseData.caseSummaryDataRows.length > 0) {
            if (props.caseData.caseSummaryDataColumns.length > minVisibleColumnCount) {
                setIsHiddenColumn(true)
                setHidenColumnCount(props.caseData.caseSummaryDataColumns.length - minVisibleColumnCount)
            }
        }

        __processData()
    }, [props]);

    useEffect(() => {
        __processData()
    }, [isHiddenColumn])

    // Function to process grid data
    const __processData = () => {
        try {


            let processedData: any = [];

            // set page size based on props
            setPageSize(props.pageSize !== "" ? props.pageSize : 10)

            if (props.caseData.caseSummaryDataRows.length > 0) {
                props.caseData.caseSummaryDataRows.forEach((res: any, index: number) => {
                    let obj: any = {};

                    res.values.forEach((tdRes: any, tdIndex: number) => {
                        const colDetails = props.caseData.caseSummaryDataColumns[tdIndex];

                        if (isHiddenColumn) {
                            if (tdIndex < minVisibleColumnCount) {
                                obj[colDetails.displayName === "" ? colDetails.name : colDetails.displayName] = parseDataByType(tdRes, colDetails.dataType);
                            }
                        } else {
                            obj[colDetails.displayName === "" ? colDetails.name : colDetails.displayName] = parseDataByType(tdRes, colDetails.dataType);
                        }
                    });

                    processedData.push(obj);
                })

                setGridData(processedData)


                if (processedData.length > 10) {
                    setIsPaginated(true)
                } else {
                    setIsPaginated(false)
                }
            } else {
                setGridData(null)
            }
        } catch (e) {
            console.log(e)
        }
    }

    // Handling scroll event to fetch more data
    const scrollHandler = (event: GridEvent) => {
        const e = event.nativeEvent;
        if (
            e.target.scrollTop + 10 >=
            e.target.scrollHeight - e.target.clientHeight
        ) {
            const moreData = gridData.splice(0, 10);
            if (moreData.length > 0) {
                setGridData((oldData: any) => oldData.concat(moreData));
            }
        }
    };


    const __renderGridToolbar = () => {
        if (!hidenColumnCount) {
            return null
        }

        return <GridToolbar>
            {/* <div className="export-btns-container">
            <button className="btn btn-primary btn-sm btn-inline-block" onClick={() => exportExcel()}>Export to Excel</button>
        </div> */}

            {/* <div className="pr-1 d-inline-block"></div>
        <button className="btn btn-primary btn-sm btn-inline-block" onClick={() => { }}>Select</button> */}
            <div className="pr-1 d-inline-block"></div>

            <button className="btn btn-primary btn-sm btn-inline-block" onClick={() => {
                setIsHiddenColumn(!isHiddenColumn);
            }}>{isHiddenColumn ? "+" : "-"} {hidenColumnCount}</button>
        </GridToolbar>
    }

    // No data, returning empty message
    if (gridData === null) {
        return <div className="p-2">{props.caseData.emptyMessage}</div>
    }


    // Main component render
    return (
        <div>
            <Grid
                data={gridData.slice(page.skip, page.take + page.skip)}
                columnVirtualization={false}

                style={{ minHeight: '400px', overflowX: "scroll" }}
                scrollable={scrollable}

                sortable={true}
                skip={page.skip}
                take={page.take}
                total={gridData.length}
                onPageChange={pageChange}
                pageSize={pageSize}

                onScroll={scrollHandler}
                fixedScroll={true}

                pageable={isPagginated ? {
                    buttonCount: 4,
                    pageSizes: false
                } : false}
            >

                {
                    __renderGridToolbar()
                }
                {props.caseData.caseSummaryDataRows.map((res: any, index: number) => {
                    res.values.map((tdRes: any, tdIndex: number) => {
                        const tdCol = props.caseData.caseSummaryDataColumns[tdIndex]
                        if (tdCol) {
                            tdCol.isHidden = tdCol?.visible === "Y" ? tdCol.width = 0 : tdCol.width = tdCol.width
                            return <GridColumn
                                field={tdRes}
                                title={tdCol.displayName === "" ? tdCol.name : tdCol.displayName}
                                width={tdCol.width === 0 ? "auto" : tdCol.width}
                            />
                        }
                    })
                })}
            </Grid>
        </div>
    )
}

export default KendoGrid;