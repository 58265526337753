// Forget password page

// import the componenet and configuration
import { useState } from "react";
import { TextField } from "../../components";
import { xanturaLogo } from "../../assets/icons";
import { ForgetPasswordState } from "../../interfaces";

const ForgetPassword = () => {
  const [state, setState] = useState<ForgetPasswordState>({
    email: "",
  });

  const __submitForgetPassword = (e: any) => {
    e.preventDefault();
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(state.email)) {
      console.log(state.email);
    } else {
      console.log("hit");
    }
  };

  const __setEmailChange = (e: any) => {
    setState({ ...state, email: e.target.value });
  };

  return (
    <div className="forget-password">
      <div className="image-container">
        <img src={xanturaLogo} alt="Xantura Logo" />
      </div>
      <div className="forget-password-form">
        {/* <form onSubmit={(e: any) => __submitForgetPassword(e)}>
          <TextField
            title="Email"
            type="email"
            name="email"
            placeholder="Enter the email"
            value={state.email}
            onChange={(e: any) => __setEmailChange(e)}
          />
          <button type="submit">submit</button>
        </form> */}

        <p className="p-3 text-center">Please contact Xantura to reset your password</p>
        <p className="ph-3 text-center">support@xantura.com</p>
      </div>
    </div>
  );
};

export default ForgetPassword;
