interface InitialStateInterface {
    data: any;
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    error: any;
}

export const setInitialState = (state: InitialStateInterface) => {
    return {
        ...state,
        isFetching: false,
        isSuccess: false,
        isError: false,
        error: null,
    }
};


export const INTERNET_OFFLINE_MESSAGE = "Network request failed";