export const ENVIRONMENT = process.env.REACT_APP_ENV;
export const APP_VERSION = process.env.REACT_APP_VERSION;

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const APP_PROJECT_NAME = process.env.REACT_APP_PROJECT_NAME;

export const MSAL_CLIENT_ID = process.env.REACT_APP_MSAL_CLIENT_ID;
export const MSAL_SCOPE = process.env.REACT_APP_MSAL_SCOPE;
export const MSAL_REDIRECT_URL = process.env.REACT_APP_MSAL_REDIRECT_URL;

export const REDIRECT_DOMAIN_NAME = process.env.REACT_APP_BASE_DOMAIN;