import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchFeedbackCSDeleteRequest, fetchFeedbackCSRequest, resetFeedbackCS, resetFeedbackCSDelete } from "../../views/case-summary/store/actions";
import { GROUP_REF_SELETECTED, TOKEN, getData } from "../../utils/Storage";
import AddFeedbackPopup from "./AddFeedbackPopup";
import KendoGrid from "../../views/case-summary/components/kendo-grid-feedback";
import { GridLoader } from "../../views/dashboard/component";
import { closeIcon } from "../../assets/icons";
import { useTranslation } from "react-i18next";

// Functional component for Information Governance
const FeedbackPopup = (props: any) => {
    const { t } = useTranslation();
    // State for storing the "involve case" data
    const dispatch = useDispatch()
    const [showPopup, setShowPopup] = useState(false)
    const [localToken, setLocalToken] = useState<any>({ isLegacy: false, token: "" });
    const [sectionNamelist, setSectionNameList] = useState([])
    const feedbackCslistdata: any = useSelector<any>(
        (state) => state.feedbackCs
    );
    const [groupRefID, setGroupRefID] = useState('')

    const feedbackCslistDataDelete: any = useSelector<any>(
        (state) => state.feedbackCsDelete
    );
    const [feedbackList, setFeedbackList] = useState<any>(null);
    
    const selectedDSP = props.dsp
    const caseSummaryType = props.caseSummaryType;
    const selectedCaseReference = props.selectedCaseReference;


    useEffect(() => {
        getData(TOKEN).then((res: any) => {
            let token;
            if (res?.provider === "msal") {
                token = { isLegacy: false, token: res.token }
            } else {
                token = { isLegacy: true, token: res.token };
            }
            setLocalToken(token);
            // feedbackCsApiCall(token)
        })
    }, [])



    const feedbackCsApiCall = (token: any) => {
        getData(GROUP_REF_SELETECTED).then((res: any) => {
            if (res !== null) {
                setGroupRefID(res)
                let body = {
                    token: token,
                    caseSummaryType: caseSummaryType,
                    dspId: selectedDSP,
                    oneViewReference: res
                };
                dispatch(fetchFeedbackCSRequest({ token: token, params: body }));
            }
        })
    }

    useEffect(() => {
        if (feedbackCslistdata.isSuccess && feedbackCslistdata.data !== null) {
            setFeedbackList(feedbackCslistdata.data)

            let list = feedbackCslistdata.data.gridColumns.filter((item: any) => {
                if (item.dataType === 'picklist')
                    return item.pickListValues
            })
            let newData = list[0].pickListValues.map((item: any) =>
                item ? { text: item.textToDisplay, value: item.valueToStore } : item
            );
            setSectionNameList(newData)



            dispatch(resetFeedbackCS());
        } else if (feedbackCslistdata.isError) {
            toast.error(
                feedbackCslistdata.data.error.data ||
                feedbackCslistdata.data.error.status
            );
            dispatch(resetFeedbackCS());
        }
    }, [feedbackCslistdata]);

    useEffect(() => {
        if (feedbackCslistDataDelete.isSuccess && feedbackCslistDataDelete.data !== null) {
            toast.success("Record deleted successfully");
            dispatch(resetFeedbackCSDelete());
            feedbackCsApiCall(localToken)

        } else if (feedbackCslistDataDelete.isError) {
            toast.error(
                feedbackCslistDataDelete.data.error.data.title
            );
            dispatch(resetFeedbackCSDelete());
        }
    }, [feedbackCslistDataDelete]);


    const __onCustomAction = (e: any) => {
        try {
            if (e.action === "delete") {
                getData(GROUP_REF_SELETECTED).then((grouprefID_res: any) => {
                    if (grouprefID_res !== null) {
                        setGroupRefID(grouprefID_res)
                        getData(TOKEN).then((res: any) => {
                            let token;
                            if (res?.provider === "msal") {
                                token = { isLegacy: false, token: res.token }

                            } else {
                                token = { isLegacy: true, token: res.token };
                            }
                            setLocalToken(token);

                            dispatch(fetchFeedbackCSDeleteRequest({
                                token: token,
                                params: {
                                    feedbackId: e.dataItem.FeedbackId,
                                    oneViewReference: grouprefID_res,

                                }
                            }))
                        })
                    }
                })
            }
        } catch (e) {
            console.log(e)
        }
    }

    const renderGridfeedback = (data: any) => {
        return (
            <div className="p-3" style={{}}>
                <KendoGrid
                    dspId={selectedDSP}
                    caseSummaryType={caseSummaryType}
                    sectionPickList={sectionNamelist}
                    caseData={data}
                    customAction={(res: any) => {
                        __onCustomAction(res)
                    }}
                    pageSize={data.length}
                />
            </div>
        )

    }
    const addpop = () => {
        return (
            <AddFeedbackPopup
                feedbackId={''}
                dspId={selectedDSP}
                caseSummaryType={caseSummaryType}
                isFrom='add'
                sectionId={props.sectionId}
                notes={''}
                sectionList={sectionNamelist}
                oneViewReference={selectedCaseReference}
                title={t('ADDFEEDBACK')}
                onCancel={(res: any) => {
                    setShowPopup(false)
                }}
            />
        )
    }

    const renderHeader = () => {
        return (
            <div className="modal-header">
                <h5 className={"aside-top-bar " + (props.title !== '' ? "justify-content-sm-between" : "")} id="exampleModalLabel">
                    {props.title}
                </h5>
                <button
                    style={{ marginRight: 2 }}
                    className="btn-header-feedback btn feedback-button "
                    onClick={() =>
                        setShowPopup(true)
                    }>{t('ADDFEEDBACK')}</button>
                <button
                    style={{ marginRight: 12, backgroundColor: '#5464B0', borderColor: '#5464B0', borderWidth: 1 }}
                    className="btn btn-transparent"
                    onClick={
                        props.onClose
                    }
                >
                    <img src={closeIcon} alt="Close icon" />
                </button>
            </div>
        )
    }

    const renderBody = () => {
        return (
            <div className="modal-body">
                {feedbackList !== null && feedbackList !== undefined ?
                    renderGridfeedback(feedbackList) : <div><GridLoader /></div>
                }
            </div>
        )
    }
    return (
        <div className="add-feedback">
            <div
                className="modal fade show"
                id="exampleModal"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >{showPopup ? addpop() :
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        {renderHeader()}
                        {renderBody()}
                    </div>
                </div>}
            </div >
        </div >
    );
};

export default FeedbackPopup;
