import { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";

export const GridLoader = () => {
  const [placeholderWidth, setPlaceholderWidth] = useState<number>((window.innerWidth * 75) / 100);


  useEffect(() => {
    setPlaceholderWidth((window.innerWidth * 75) / 100);
  }, [window.innerWidth]);

  return (
    <ContentLoader
      viewBox={`50 50 ${placeholderWidth - 20} 550`}
      backgroundColor="#eaeced"
      foregroundColor="#ffffff"
    >
      <rect x="51" y="45" rx="3" ry="3" width={placeholderWidth} height="17" />
      <circle cx="879" cy="123" r="11" />
      <circle cx="914" cy="123" r="11" />
      <rect x="104" y="115" rx="3" ry="3" width="141" height="15" />
      <rect x="305" y="114" rx="3" ry="3" width="299" height="15" />
      <rect x="661" y="114" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="155" rx="3" ry="3" width={placeholderWidth} height="2" />
      <circle cx="880" cy="184" r="11" />
      <circle cx="915" cy="184" r="11" />
      <rect x="105" y="176" rx="3" ry="3" width="141" height="15" />
      <rect x="306" y="175" rx="3" ry="3" width="299" height="15" />
      <rect x="662" y="175" rx="3" ry="3" width="141" height="15" />
      <rect x="56" y="216" rx="3" ry="3" width={placeholderWidth} height="2" />
      <circle cx="881" cy="242" r="11" />
      <circle cx="916" cy="242" r="11" />
      <rect x="106" y="234" rx="3" ry="3" width="141" height="15" />
      <rect x="307" y="233" rx="3" ry="3" width="299" height="15" />
      <rect x="663" y="233" rx="3" ry="3" width="141" height="15" />
      <rect x="57" y="274" rx="3" ry="3" width={placeholderWidth} height="2" />
      <circle cx="882" cy="303" r="11" />
      <circle cx="917" cy="303" r="11" />
      <rect x="107" y="295" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="294" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="294" rx="3" ry="3" width="141" height="15" />
      <rect x="58" y="335" rx="3" ry="3" width={placeholderWidth} height="2" />
      <circle cx="881" cy="363" r="11" />
      <circle cx="916" cy="363" r="11" />
      <rect x="106" y="355" rx="3" ry="3" width="141" height="15" />
      <rect x="307" y="354" rx="3" ry="3" width="299" height="15" />
      <rect x="663" y="354" rx="3" ry="3" width="141" height="15" />
      <rect x="57" y="395" rx="3" ry="3" width={placeholderWidth} height="2" />
      <circle cx="882" cy="424" r="11" />
      <circle cx="917" cy="424" r="11" />
      <rect x="107" y="416" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="415" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="415" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="453" rx="3" ry="3" width={placeholderWidth} height="2" />
      <rect x="51" y="49" rx="3" ry="3" width="2" height="465" />
      <rect x="955" y="49" rx="3" ry="3" width="2" height="465" />
      <circle cx="882" cy="484" r="11" />
      <circle cx="917" cy="484" r="11" />
      <rect x="107" y="476" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="475" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="475" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="513" rx="3" ry="3" width={placeholderWidth} height="2" />
      <rect x="52" y="80" rx="3" ry="3" width="906" height="17" />
      <rect x="53" y="57" rx="3" ry="3" width="68" height="33" />
      <rect x="222" y="54" rx="3" ry="3" width="149" height="33" />
      <rect x="544" y="55" rx="3" ry="3" width="137" height="33" />
      <rect x="782" y="56" rx="3" ry="3" width="72" height="33" />
      <rect x="933" y="54" rx="3" ry="3" width="24" height="33" />
    </ContentLoader>
  );
};

// loader for the casesummary collapse section
export const CollapseLoader = () => {
  const [placeholderWidth, setPlaceholderWidth] = useState<number>(0);

  useEffect(() => {
    setPlaceholderWidth((window.innerWidth * 75) / 100);
  }, [window.innerWidth]);

  return (
    <ContentLoader
      viewBox={`50 50 ${placeholderWidth - 20} 550`}
      backgroundColor="#eaeced"
      foregroundColor="#ffffff"
    >
      <rect x="51" y="45" rx="3" ry="3" width={placeholderWidth} height="17" />
      <rect x="104" y="115" rx="3" ry="3" width="141" height="15" />
      <rect x="305" y="114" rx="3" ry="3" width="299" height="15" />
      <rect x="661" y="114" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="155" rx="3" ry="3" width={placeholderWidth} height="2" />
      <rect x="105" y="176" rx="3" ry="3" width="141" height="15" />
      <rect x="306" y="175" rx="3" ry="3" width="299" height="15" />
      <rect x="662" y="175" rx="3" ry="3" width="141" height="15" />
      <rect x="56" y="216" rx="3" ry="3" width={placeholderWidth} height="2" />
      <rect x="106" y="234" rx="3" ry="3" width="141" height="15" />
      <rect x="307" y="233" rx="3" ry="3" width="299" height="15" />
      <rect x="663" y="233" rx="3" ry="3" width="141" height="15" />
      <rect x="57" y="274" rx="3" ry="3" width={placeholderWidth} height="2" />
      <rect x="107" y="295" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="294" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="294" rx="3" ry="3" width="141" height="15" />
      <rect x="58" y="335" rx="3" ry="3" width={placeholderWidth} height="2" />
      <rect x="106" y="355" rx="3" ry="3" width="141" height="15" />
      <rect x="307" y="354" rx="3" ry="3" width="299" height="15" />
      <rect x="663" y="354" rx="3" ry="3" width="141" height="15" />
      <rect x="57" y="395" rx="3" ry="3" width={placeholderWidth} height="2" />
      <rect x="107" y="416" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="415" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="415" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="453" rx="3" ry="3" width={placeholderWidth} height="2" />
      <rect x="51" y="49" rx="3" ry="3" width="2" height="465" />
      <rect x="955" y="49" rx="3" ry="3" width="2" height="465" />
      <rect x="107" y="476" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="475" rx="3" ry="3" width="299" height="15" />
      <rect x="664" y="475" rx="3" ry="3" width="141" height="15" />
      <rect x="55" y="513" rx="3" ry="3" width={placeholderWidth} height="2" />
      <rect x="52" y="80" rx="3" ry="3" width="906" height="17" />
      <rect x="53" y="57" rx="3" ry="3" width="68" height="33" />
      <rect x="222" y="54" rx="3" ry="3" width="149" height="33" />
      <rect x="544" y="55" rx="3" ry="3" width="137" height="33" />
      <rect x="782" y="56" rx="3" ry="3" width="72" height="33" />
      <rect x="933" y="54" rx="3" ry="3" width="24" height="33" />
    </ContentLoader>
  );
};

// loader for the login screen
export const LoginLoader = () => (
  <ContentLoader
    speed={1}
    width={512}
    height={400}
    viewBox="0 0 512 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <text textAnchor="left" x="50" y="55" fontSize="15">
      Username
    </text>
    <rect x="50" y="70" rx="0" ry="0" width="420" height="30" />
    <text textAnchor="left" x="50" y="140" fontSize="15">
      Password
    </text>
    <rect x="50" y="150" rx="0" ry="0" width="420" height="30" />
    <text textAnchor="left" x="50" y="230" fontSize="15" fontStyle="italic">
      Forgotten your password?
    </text>
    {/* <circle cx="277" cy="147" r="44" /> */}
    {/* <rect x="174" y="53" rx="0" ry="0" width="216" height="41" />
      <rect x="198" y="207" rx="0" ry="0" width="160" height="9" /> */}
    {/* <rect x="231" y="236" rx="0" ry="0" width="92" height="9" /> */}
    <rect x="50" y="240" rx="0" ry="0" width="420" height="30" />
    <text textAnchor="middle" x="250" y="300" fontSize="15">
      OR
    </text>
    <rect x="50" y="320" rx="0" ry="0" width="420" height="30" />
  </ContentLoader>
);

// Loader for the aside data on case management
export const AsideFilterLoader = () => (
  <ContentLoader viewBox="0 0 380 450" width={400} height={450}>
    <rect x="0" y="30" rx="0" ry="0" width="258" height="25" />
    <rect x="0" y="60" rx="0" ry="0" width="400" height="30" />
    <rect x="0" y="120" rx="0" ry="0" width="258" height="25" />
    <rect x="0" y="150" rx="0" ry="0" width="400" height="30" />
    <rect x="0" y="210" rx="0" ry="0" width="258" height="25" />
    <rect x="0" y="240" rx="0" ry="0" width="400" height="30" />
    <rect x="0" y="300" rx="0" ry="0" width="258" height="25" />
    <rect x="0" y="330" rx="0" ry="0" width="400" height="30" />
    <rect x="0" y="390" rx="0" ry="0" width="258" height="25" />
    <rect x="0" y="420" rx="0" ry="0" width="400" height="30" />
  </ContentLoader>
);

export const CaseSummaryLoader = () => {
  return (
    <ContentLoader
      height="100vh"
      width="100vw"
      viewBox="0 0 510 200"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="40" rx="0" ry="0" width="75" height="10" />
      <rect x="0" y="60" rx="0" ry="0" width="75" height="10" />
      <rect x="0" y="100" rx="0" ry="0" width="75" height="10" />
      <rect x="0" y="80" rx="0" ry="0" width="75" height="10" />
      <rect x="0" y="120" rx="0" ry="0" width="75" height="10" />
      <rect x="110" y="40" rx="0" ry="0" width="370" height="125" />
      <rect x="275" y="63" rx="0" ry="0" width="72" height="4" />
      <rect x="430" y="5" rx="5" ry="5" width="75" height="20" />
      <rect x="110" y="10" rx="0" ry="0" width="200" height="15" />
      <rect x="0" y="5" rx="4" ry="4" width="75" height="20" />
    </ContentLoader>
  );
};
