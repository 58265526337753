import { TOKEN } from "../../utils/Storage";

const useLegacySupport:any = (props:any) => {
    const urlParams = new URLSearchParams(window.location.search).get("coreKey");
    const isSummaryOneView =(window.location.pathname === "/caseSummaryOneView" ? true : false);
    
    // Utility function to safely parse JSON
    const safeParseJSON = (value: string | null): any => {
        try {
            if (value === null) {
                return null;
            }
            
            let token:any = JSON.parse(value);
            return { ...token, isLegacy: token?.provider === "msal" } ;
        } catch (error) {
            console.error("Failed to parse token:", error);
            return null;
        }
    };

    const token= urlParams || safeParseJSON(localStorage.getItem(TOKEN)) || null;
    const isLegacySupported = (urlParams !== null && urlParams.length > 0) || false;

    return { isLegacySupported, token, isSummaryOneView }
}

export default useLegacySupport;