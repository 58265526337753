const communityAssetsEvent = new CustomEvent("communityAssets", {
    detail: {
        selectedSCVId: "",
    }
});
const fetchChildrendDataEvent = new Event("fetchChildrendData");


export {
    communityAssetsEvent,
    fetchChildrendDataEvent
}