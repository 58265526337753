import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { addCaseDetailAll, addEditPopup, addOutcomesData, deleteOutcomeData, editOutcomeData, getEditOutcomeData, resetAddEditPopup, resetAddOutcomesData, resetCaseDetailAll, resetDeleteOutcomeData, resetEditOutcomeData, resetGetEditOutcomeData } from "../store/actions";
import { Button } from "../../../components";
import { GridLoader } from "../../dashboard/component";
import KendoGrid from "../../../components/containers/kendo-grid"
import caseSummaryService from "../services/case-summary-service";
import CustomAction from "../../dashboard/component/customAction";
import AddEngagmentPopup from "./addEngagmentPopup";


interface PageState {
    skip: number,
    take: number
}
// Initial pagination state
const initialDataState: PageState = { skip: 0, take: 25 };

const OutcomesGrid: React.FC<any> = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const [addOutcomeModel, setAddOutcomeModel] = useState<Boolean>(false);
    const [addOutcomeModelDataState, setAddOutcomeModelDataState] = useState<any>();
    const [outcomeListData, setOutcomeListData] = useState([]);
    const [totalDataCount, setTotalDataCount] = useState<number | null>(null);
    const [totalPagesCount, setTotalPagesCount] = useState<number | null>(null);
    const [recordsPerPage, setRecordsPerPage] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number | null>(1);
    const [dataState, setDataState] = useState<any>(initialDataState);
    const [gridData, setGridData] = useState<any>(null);
    const [hiddenCountColumns, setHiddenCountColumns] = useState<any>();
    const [gridDataRows, setGridDataRows] = useState<any>([]);
    const [editCasePopup, setEditCasePopup] = useState<any>(false);
    const [editCaseData, setEditCaseData] = useState<any>(null);
    const [editOutcomeModelDataState, setEditOutcomeModelDataState] = useState<any>(null);

    const addEditOutcomeData: any = useSelector<any>((state) => state.addEditCM);
    const caseDetailAlldata = useSelector((state: any) => state.caseDetailAllCM);
    const getEditDetailData = useSelector((state: any) => state.getEditOutcomeCM);
    const addPopupData = useSelector((state: any) => state.addOutcomeCM);
    const deleteDetailData = useSelector((state: any) => state.deleteDetailDataCM);
    const editPopupOutcomeData = useSelector((state: any) => state.editOutcomeDataCM);




    useEffect(() => {
        // Fetch Information addCaseDetailAll
        fetchOutComeData();
    }, [])

    const fetchOutComeData = () => {
        try {
            dispatch(
                addCaseDetailAll({
                    token: props.token,
                    param: {
                        dspId: props.dsp,
                        oneViewReference: props.selectedCaseReference,
                        controlId: props.tabs.datagrid.dataGridId,
                        pageNumber: currentPage,
                        pageSize: recordsPerPage
                    },
                    body: "",
                })
            );
        } catch (e: any) {
            toast.error(e.message);
        }
    }

    useEffect(() => {
        // Process received addOutcomeModel data
        if (addEditOutcomeData.data !== addOutcomeModelDataState) {

            try {
                if (addEditOutcomeData.isSuccess && addEditOutcomeData.data !== null) {
                    setAddOutcomeModelDataState(addEditOutcomeData.data);
                    dispatch(resetAddEditPopup());
                } else if (addEditOutcomeData.isError) {
                    toast.error(addEditOutcomeData?.data?.error?.data);
                    dispatch(resetAddEditPopup());
                    setAddOutcomeModel(false);
                }
            } catch (e: any) {
                toast.error(e.message);
            }
        }
    }, [addEditOutcomeData]);

    useEffect(() => {
        try {
            if (addPopupData.isSuccess && addPopupData.data !== null) {
                // toast.success("Case Details add successful");
                setAddOutcomeModel(false);
                setAddOutcomeModelDataState(null);
                fetchOutComeData();
                dispatch(resetAddOutcomesData());
            } else if (addPopupData.isError) {
                toast.error(addPopupData?.data?.error);
                setAddOutcomeModel(false);
                setAddOutcomeModelDataState(null);
                dispatch(resetAddOutcomesData());
            }
            // props.fetchOutComeData();
        } catch (e: any) {
            toast.error(e.message);
        }
    }, [addPopupData])

    useEffect(() => {
        try {
            if (deleteDetailData.isSuccess && deleteDetailData.data !== null) {
                // toast.success("Case Details data delete successful");
                dispatch(resetDeleteOutcomeData());
                fetchOutComeData();
            } else if (deleteDetailData.isError) {
                toast.error(deleteDetailData?.data?.error);
                fetchOutComeData();
                dispatch(resetDeleteOutcomeData());
            }
        } catch (e: any) {
            toast.error(e.message);
        }
    }, [deleteDetailData])

    const _addOutcome = () => {
        setAddOutcomeModel(true);
    }

    useEffect(() => {
        if (addOutcomeModel) {
            try {
                // Fetch Information add_edit_cofig
                dispatch(
                    addEditPopup({
                        token: props.token,
                        param: {
                            dspId: props.dsp,
                            oneViewReference: props.selectedCaseReference,
                            controlId: props.tabs.datagrid.dataGridId,
                        },
                    })
                );
            } catch (e: any) {
                toast.error(e.message);
            }
        }
    }, [addOutcomeModel]);

    const __submitIgPluginAuth = (type: string, data: any) => {
        try {
            if (type === "closeForm") {
                setAddOutcomeModel(false);
            } else if (type === "formSubmit") {

                /**
                 * Dispatch action to Add outcome data
                 **/
                let convertedArray: any = [];
                for (const key in data) {
                    if (data[key] !== '') {
                        convertedArray.push({
                            "controlName": key,
                            "values": data[key]
                        })
                    }
                }
                try {
                    dispatch(
                        addOutcomesData({
                            token: props.token,
                            param: {
                                dspId: +props.dsp,
                                oneViewReference: props.selectedCaseReference,
                                controlId: addOutcomeModelDataState.popupForm.controls[0].associatedControlId,
                            },
                            body: convertedArray,
                        })
                    );
                } catch (e: any) {
                    toast.error(e.message);
                }

            }
        } catch (e: any) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        if (caseDetailAlldata.isSuccess && caseDetailAlldata.data !== null) {
            if (caseDetailAlldata.isSuccess && caseDetailAlldata.data !== null) {
                // setOutcomeListData(caseDetailAlldata.data)
                const masterData = caseDetailAlldata.data;
                const paging = masterData.paging;
                const data = caseSummaryService.processDataForKendoGrid(masterData)

                setDataState({
                    skip: (paging?.currentPage * recordsPerPage) - recordsPerPage,
                    take: recordsPerPage
                })

                // setOutcomeListData(data)
                setGridData(data);
                // setGrdDataRows({ data: data.gridRows, total: paging?.totalCount })

                setTotalDataCount(paging?.totalCount)
                setTotalPagesCount(paging?.totalPages)

                dispatch(resetCaseDetailAll())
            } else if (caseDetailAlldata.isError) {
                console.error(caseDetailAlldata)
                toast.error(caseDetailAlldata.data.error.message)
                dispatch(resetCaseDetailAll())
            }
        }

    }, [caseDetailAlldata])


    useEffect(() => {
        // Process received editOutcomeModel data
        if (getEditDetailData?.data !== editOutcomeModelDataState) {


            try {
                if (getEditDetailData?.isSuccess && getEditDetailData?.data !== null) {

                    setEditOutcomeModelDataState(getEditDetailData.data);
                    setEditCasePopup(true);
                    dispatch(resetGetEditOutcomeData());
                } else if (getEditDetailData?.isError) {
                    toast.error(getEditDetailData?.data?.error?.data);
                    dispatch(resetGetEditOutcomeData());
                }
            } catch (e: any) {
                toast.error(e.message);
            }
        }
    }, [getEditDetailData]);

    useEffect(() => {
        // Process received editPopupOutcomeData data

        try {
            if (editPopupOutcomeData.isSuccess && editPopupOutcomeData.data !== null) {
                // toast.success("Case Details edit successful");
                setEditCasePopup(false);
                fetchOutComeData();
                dispatch(resetEditOutcomeData());
            } else if (editPopupOutcomeData.isError) {
                toast.error(editPopupOutcomeData?.data?.error);
                setEditCasePopup(false);
                fetchOutComeData();
                dispatch(resetEditOutcomeData());
            }
        } catch (e: any) {
            toast.error(e.message);
        }

    }, [editPopupOutcomeData]);

    const __onCustomAction = (res: any) => {
        try {
            switch (res.action) {
                case "delete":
                    dispatch(deleteOutcomeData({
                        token: props.token,
                        param: {
                            controlId: props.tabs.datagrid.dataGridId,
                            dspId: props.dsp,
                            id: res.data.Id
                        }
                    }))
                    break;
                case "edit":
                    dispatch(
                        getEditOutcomeData
                            ({
                                token: props.token,
                                param: {
                                    // controlId: res.data.linkFormControlId,
                                    controlId: props.tabs.datagrid.dataGridId,
                                    dspId: props.dsp,
                                    id: res.data.Id,
                                },

                            }));
                    break;
                default:
                    break;

            }
            setAddOutcomeModel(false);
            setAddOutcomeModelDataState(null);
        } catch (res) {
            console.log(res);
        }
    }

    const __onPageChange = (res: any) => {
        setRecordsPerPage(res.page.take)
        let page = (res.page.skip / res.page.take) + 1
        setCurrentPage(page)
    }

    const renderOutcomeList = () => {
        return (
            gridData !== null ? (
                <KendoGrid
                    gridData={gridData}
                    pageSize={recordsPerPage}
                    getEditDetailData={getEditDetailData}
                    token={props.token}
                    dsp={props.dsp}
                    selectedCaseReference={props.selectedCaseReference}
                    fetchOutComeData={fetchOutComeData}
                    onPageChange={(res: any) => __onPageChange(res)}
                    onHiddenCount={(e: any) => {
                        setHiddenCountColumns(e)
                    }}

                    expandField=""

                    isSelectable={false}
                    isCustomAction={true}
                    customeActionLayout={(resData: any) => <CustomAction
                        data={resData}
                        allowDetails={false}
                        allowEdit={true}
                        allowDelete={true}
                        onClick={(e: any) => __onCustomAction(e)}
                    />}
                />) : null
        )

    }

    const __submitEditCaseRecord = (type: string, data: any) => {

        try {
            if (type === "closeForm") {
                setEditCasePopup(false);
                // setEditOutcomeModelDataState(null);
            } else if (type === "formSubmit") {
                let convertedArray: any = [];
                for (const key in data) {
                    if (data[key] !== '' && key !== 'Id' && key !== 'GroupRef') {
                        convertedArray.push({
                            "controlName": key,
                            "values": data[key]
                        })
                    }
                }
                try {
                    dispatch(
                        editOutcomeData({
                            token: props.token,
                            param: {
                                dspId: +props.dsp,
                                oneViewReference: props.selectedCaseReference,
                                controlId: editOutcomeModelDataState[0].associatedControlId,
                                id: editOutcomeModelDataState[0].value,
                            },
                            body: convertedArray,
                        })
                    );
                } catch (e: any) {
                    toast.error(e.message);
                }

            }
        } catch (e: any) {
            toast.error(e.message);
        }
    }


    return (
        <div className="outcome-grid">
            <div className="filter-content pos-relative">
                <div className="filter-header">
                    <h3></h3>
                    <div style={{ justifyContent: "center", alignContent: "center" }}>
                        <Button
                            style={{ backgroundColor: "#ffffff", border: "none", color: "#5464B0", padding: "0.625rem 1.188rem", fontWeight: "600", borderRadius: "0.625rem" }}
                            key={""}
                            title={t("ADDOUTCOME")}
                            type={"button"}
                            onClick={() => _addOutcome()}
                        />
                    </div>
                </div>
            </div>
            {caseDetailAlldata.isFetching ? <GridLoader /> : renderOutcomeList()}
            {/* {renderOutcomeList()} */}
            {(addOutcomeModel) &&
                <AddEngagmentPopup
                    token={props.token}
                    dspId={props.dsp}
                    title={"Outcome"}
                    data={addOutcomeModelDataState}
                    isFetching={false}
                    onSubmit={(type: string, res: any) => {
                        if (res === null) {
                            __submitIgPluginAuth("closeForm", res)
                        }
                        else {
                            __submitIgPluginAuth(type, res)
                        }
                    }}
                />}

            {(editCasePopup) &&
                <AddEngagmentPopup
                    token={props.token}
                    dspId={props.dsp}
                    title={"Outcome"}
                    data={{ popupForm: { controls: editOutcomeModelDataState } }}
                    isFetching={false}
                    onSubmit={(type: string, res: any) => {
                        if (res === null) {
                            __submitEditCaseRecord("closeForm", res)
                        }
                        else {
                            __submitEditCaseRecord(type, res)
                        }
                    }}
                />}

        </div>
    );
};

export default OutcomesGrid;
