import { createBrowserRouter, Outlet } from "react-router-dom";
import Dashboard from "../views/dashboard";
// TODO: add case summary route
import CommunityAssets from "../views/community-assets";

export const WrapperComponent = () => {
    return (<Outlet />)
}

// Create a browser router and define routes
const DashboardStack = createBrowserRouter([{
    // Root route ("/")
    path: "/",
    // Renders the Auth component when the user visits the root route
    element: <WrapperComponent />,
    // Renders a div with "404" when an error occurs in this route
    // errorElement: <Error404 />,
    children: [
        {path: "/",element: <Dashboard />},
        { path: "/create-case", element: <div>Create Case</div>},
        { path: "/case-summary/:casesummaryid/:tabid", element: <Dashboard /> },
        { path: "/community-assets", element: <Dashboard /> },
        { path: "/reporting", element: <CommunityAssets /> }
    ]}
]);

export default DashboardStack;