// Import the Moment.js library for handling dates and times
import moment from "moment";
import axios from 'axios';
import { toast } from "react-toastify";

/**
 * Asynchronously checks if the network is connected.
 * 
 * @returns {Promise<boolean>} Returns a boolean value indicating network status.
 */
export const _isNetworkConnected = async () => {
  // Currently hardcoded to return false, this function could be
  // implemented to actually check network status
  return false;
};

/**
 * Formats a date to DD/MM/YYYY format.
 * 
 * @param {any} date - The date to be formatted.
 * @returns {string} Returns the date formatted as a string in DD/MM/YYYY.
 */
export const dateFormat = (date: any) => {
  // Use Moment.js to format the date to DD/MM/YYYY
  const finalDate = moment(date).format('DD/MM/YYYY');
  return finalDate;
};

/**
 * Encodes an object to a URL-encoded string format.
 * 
 * @param {any} data - The object to be encoded.
 * @returns {string} Returns a URL-encoded string.
 */
export const encodeFormData = (data: any) => {
  // Initialize an array to hold each encoded item
  let formBody = [];

  // Loop through each property in the object
  for (let property in data) {
    // Encode the property name
    let encodedKey = encodeURIComponent(property);
    // Encode the property value
    let encodedValue = encodeURIComponent(data[property]);
    // Add them to the array in key=value format
    formBody.push(encodedKey + '=' + encodedValue);
  }
  // Join the array into a single string, separating items with '&'
  let formBodystr = formBody.join('&');

  return formBodystr;
};


export const toSeoUrl = (url: string) => {

  // make the url lowercase         
  let encodedUrl: string = url.toString().toLowerCase();

  // replace & with and           
  encodedUrl = encodedUrl.split(/\&+/).join("-and-")

  // remove invalid characters 
  encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");

  // remove duplicates 
  encodedUrl = encodedUrl.split(/-+/).join("-");

  // trim leading & trailing characters 
  encodedUrl = encodedUrl.trim();

  return encodedUrl;
}

interface _TokenProps {
  isLegacy: boolean,
  token: string
}


const __handleError = (error: any) => {
  if (error.response) {

    switch (error.response.status) {
      case 401:
        toast.error(error.response.data);
        // Redirect to login page
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 3000);
        break;

      case 403:
        // Redirect to forbidden page

        break;

      case 500:
        // Redirect to forbidden page
        // toast.error("Internal server error");
        console.log ("Error API: ", error.response.data);
        break;

      default:
        break;
    }

  }
  return Promise.reject(error);
}

export const getAxiosInstanceWithoutToken = () => {
  const axiosInstance = axios.create({ headers: {} });
  axiosInstance.interceptors.response.use(
    response => response,
    error => __handleError(error)
  )

  // Create axios instance with headers
  return axiosInstance

}

export const getHeaders = (token: _TokenProps) => {
  let headers = {};
  if (token !== null) {
    if (token.isLegacy) {
      headers = {
        "X-Core-Key": token.token,
      }
    } else {
      headers = {
        "Authorization": `Bearer ${token.token}`
      }
    }
  }

  return headers;
}


export const getNewAxiosInstance = (token: _TokenProps) => {
  let headers = {};
  if (token !== null) {
    if (token.isLegacy) {
      headers = {
        "X-Core-Key": token.token,
      }
    } else {
      headers = {
        "Authorization": `Bearer ${token.token}`
      }
    }
  }


  const axiosInstance = axios.create({ headers });
  axiosInstance.interceptors.response.use(
    response => response,
    error => __handleError(error)
  )

  // Create axios instance with headers
  return axiosInstance
}