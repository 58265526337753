// Import action type constants from another module
import * as types from "../types";

// Import a function that sets the initial state
import { setInitialState } from "../../../../store/InitialState";


// Initialize the state with a setInitialState function
// The state structure includes data, isFetching, isSuccess, isError, and error properties
const INITIAL_STATE = setInitialState({
    data: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    error: false
});

// The default export is a reducer function
export default function (state = INITIAL_STATE, action: any) {

    // Use a switch-case to handle different action types
    switch (action.type) {

        // This case handles the start of a data fetch operation
        case types.CASE_SUMMARY_DATA_CM_REQUEST:
            return {
                ...state,
                isFetching: true, // Set isFetching to true to indicate a network request is in progress
            };

        // This case handles successful data fetch operations
        case types.CASE_SUMMARY_DATA_CM_RECEIVE:
            return {
                ...state,
                isFetching: false, // Set isFetching to false as network request is complete
                isSuccess: true, // Set isSuccess to true as the operation was successful
                data: action.payload // Store the received data
            };

        // This case handles failed data fetch operations
        case types.CASE_SUMMARY_DATA_CM_FAILURE:
            // Check if it's a network error
            if (action.payload && action.payload.error === "Network Error") {
                return {
                    ...state,
                    isError: true,
                    isFetching: false,
                    isSuccess: false,
                    data: { message: "INTERNET_OFFLINE_MESSAGE" } // Store an offline error message
                };
            } else {
                return {
                    ...state,
                    isFetching: false,
                    isError: true,
                    isSuccess: false,
                    data: action.payload, // Store the error response
                };
            };

        // This case resets the state to its initial configuration
        case types.RESET_CASE_SUMMARY_DATA_CM:
            return {
                ...state,
                isFetching: false,
                isError: false,
                data: null, // Reset the data to null
            };

        // The default case returns the current state unchanged
        default:
            return {
                ...state
            }
    }
}

