import axios from "axios";
import { useSelector } from "react-redux";

import { API_ENDPOINT } from "./config/ApiConstants";
import { ToastContainer } from "react-toastify";


// Setting default headers for Axios HTTP client
axios.defaults.baseURL = API_ENDPOINT;
axios.defaults.headers.post['Content-Type'] = 'application/json';

// Main app component 
function App(props: any) {
  // Call the custom hook to check if legacy token acquisition is supported
  const uiStates: any = useSelector<any>(state => state.uiStates);

  // Main JSX for the App component
  return (
    <div className={uiStates.isPrintable ? "print" : "screen"}>
      <ToastContainer />
      <div className="legacySupported">
        {props.children}
      </div>
    </div>
  );
}

export default App;