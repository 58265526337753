import { closeIcon } from "../../../assets/icons";
import { t } from "i18next";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { addEditPopup, resetAddEditPopup } from "../store/actions";
import { addNewCase, resetAddNewCase, resetSearchCase, searchCase } from "../../dashboard/store/actions";
import { Loader } from "../../../components";
import { toast } from "react-toastify";

// Functional component for Create case
const CreateCasePopup = (props: any) => {
  const dispatch = useDispatch();

  const popupData: any = useSelector<any>((state) => state.addEditCM);
  const searchCaseData: any = useSelector<any>((state) => state.searchCaseData);
  const addNewCaseData: any = useSelector<any>((state) => state.addNewCaseData);



  const [selectedCase, setSelectedCase] = useState<any>(false);
  const [selectedCaseId, setSelectedCaseId] = useState<any>(null);
  const [searchKey, setSearchKey] = useState<string>("");
  const [listData, setListData] = useState<any>(null);
  const [configData, setConfigData] = useState<any>(null);
  const [enableCreateCase, setEnableCreateCase] = useState<boolean>(true);

  useEffect(() => {
    dispatch(addEditPopup({
      token: props.token,
      param: {
        dspId: props.dspId,
        controlId: props.data.controlId
      }
    }))
  }, [])

  useEffect(() => {
    if (popupData.isSuccess && popupData.data !== null) {
      // console.log(popupData.data)

      setConfigData(popupData.data.popupForm);
      
      dispatch(resetAddEditPopup());
    } else if (popupData.isError) {

      dispatch(resetAddEditPopup());
    }

    if (searchCaseData.isSuccess && searchCaseData.data !== null) {
      setListData(searchCaseData.data);
      setSelectedCaseId(null)
      setEnableCreateCase(false)

      dispatch(resetSearchCase());
    } else if (searchCaseData.isError) {
      if (searchCaseData.data.error.response.status === 404) {
        setListData({ rowCount: 0, emptyMessage: "No records found" })
      } else {
        setListData(null);
      }

      setEnableCreateCase(false);
      setSelectedCaseId(null)
      toast.error(searchCaseData.data.message);
      dispatch(resetSearchCase());
    }


    if (addNewCaseData.isSuccess && addNewCaseData.data !== null) {

      toast.success(addNewCaseData.data.message);
      props.onSubmit("refereshGrid", null)
      dispatch(resetAddNewCase());
    } else if (addNewCaseData.isError) {
      toast.error(addNewCaseData.data.message || addNewCaseData.data.error.response.data);
      dispatch(resetAddNewCase());
    }

  }, [popupData, addNewCaseData, searchCaseData])

  // Function to render form iframe
  const _renderFormIframe = () => {
    const iframeUrl = props?.iframeData;



    return (
      <div style={{ width: "100%", height: "80vh" }}>
        <iframe
          src={iframeUrl}
          style={{ width: "100%", height: "100%", border: "none" }}
          title="Form Iframe"
        />
      </div>
    );
  };


  const __renderCases = () => {
    if (searchCaseData.isFetching) {
      return <Loader />
    }


    if (listData !== null && listData.rowCount >= 1) {
      return <div style={{maxHeight: "50vh", overflow:"auto"}}> {listData.gridRows.map((element: any, index: number) => {
        let value = element.values
        return (<div key={"form" + index}>
          <label className="d-flex pb-3">
            <div className="p-3">
              <input type="radio" id={"checkbox" + index} name={"case"} onChange={(e) => setSelectedCaseId(element)} />
            </div>
            <div className="flex-grow-1">
              <p className="m-0"><b>{value[2]}</b> {value[0]}</p>
              <p>{value[4]}</p>
            </div>
          </label>
        </div>)
      })
      }
      </div>
    } else if (listData !== null && listData.rowCount === 0) {
      return <div>{listData.emptyMessage}</div>
    }
  }



  const __onSearchSubmit = (e: any) => {
    e.preventDefault();

    dispatch(searchCase({
      token: props.token,
      param: {
        dspId: props.dspId,
        controlId: configData.controls.find((item: any) => item.name === "btnSearch").associatedControlId,
        searchKey: searchKey
      }
    }));
    // setSearchKey(e.target.value);

    // dispatch(searchCase({
    //   token: props.token,
    //   param: {
    //     dspId: props.dspId,
    //     controlId: 117,
    //     searchKey: e.target.value
    //   }
    // }));
  }

  const __OnClickSelectCase = () => {
    if (selectedCaseId !== null) {
      dispatch(addNewCase({
        token: props.token,
        param: {
          dspId: props.dspId,
          scvId: selectedCaseId.values[0],
          oneViewReference: selectedCaseId.values[1]
        }
      }));
    }
  }

  const __onCreateCase = () => {
    setSelectedCase(true);
    props.onSubmit("createCase", null)
  }

  const __renderFormLayout = () => {
    if (configData !== null) {
      return configData.controls.map((item: any, index: number) => {
        switch (item.name) {
          case "search":
            return <input type="text" className="form-control  p-3" placeholder={item.label} onChange={(e: any) => setSearchKey(e.target.value)} />;
          case "btnSearch":
            return <button type="submit" className="btn btn-primary">{item.label}</button>;

        }
      })
    }
  }

  const __renderFooterLayout = () => {
    if (configData !== null) {
      return configData.controls.map((item: any, index: number) => {
        switch (item.name) {
          case "addCase":
            return <div className="flex-grow-1">
              <button className="btn btn-primary" disabled={selectedCaseId !== null ? false : true} onClick={__OnClickSelectCase}>{item.label}</button>
            </div>;
          case "createCase":
            return <div>
              <button className="btn btn-primary" onClick={__onCreateCase}
                disabled={enableCreateCase}
              >{item.label}</button>
            </div>;

        }
      })
    }
  }

  return (
    <div className="addoutcome-popup">
      <div
        className="modal fade show"
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t("CREATECASESUMMARY")}
              </h5>
              <button
                className="btn btn-transparent close"
                onClick={() => props.onSubmit("", null)}
              >
                <img src={closeIcon} alt="close" />
              </button>
            </div>
            <div className="modal-body">
              {
                popupData.isFetching ? <Loader /> : ""
              }
              {
                !selectedCase ?
                  <div>
                    <div className="pb-3">
                      <form onSubmit={__onSearchSubmit}>
                        <div className="input-group">
                          {__renderFormLayout()}
                        </div>
                      </form>
                    </div>
                    {__renderCases()}

                    <div className="d-flex">
                      {__renderFooterLayout()}


                    </div>
                  </div> :
                  <div className="content-container">{_renderFormIframe()}</div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCasePopup;
