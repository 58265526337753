import React from 'react';

interface ListProps {
    title: string;
    value: string;
}

interface ToggleProps {
    selected: ListProps;
    list: ListProps[];
    isDisabled?: boolean;
    onClick: (item: any) => void;
}

const Toggle: React.FC<ToggleProps> = ({ selected, list, isDisabled = false,  onClick }) => {

    const data: any = list.map((item: any) => {
        return (<button
            key={item.value}
            disabled={isDisabled}
            onClick={() => onClick(item)}
            className={selected.value === item.value
                    ? "toggle active"
                    : "toggle"
            }
        >{item.title}</button>)
    });


    return (<div className="toggle-wrapper">
        {data}
    </div>);
};

export default Toggle;
