import { useEffect, useState } from "react"
import { parseDataByType } from "../../../utils"

const DataGrid: any = (props: any) => {
    const [rowsData, setRowsData] = useState<any>(null)
    const [columnsData, setColumnsData] = useState<any>(null)
    const [emptyMessage, setEmptyMessage] = useState<any>(null)


    useEffect(() => {
        if (props.caseData.caseSummaryDataColumns.length > 0) {
            setColumnsData(props.caseData.caseSummaryDataColumns)
            setRowsData(props.caseData.caseSummaryDataRows)

            if (props.caseData.emptyMessage !== '') {
                setEmptyMessage(props.caseData.emptyMessage)
            } else {
                setEmptyMessage(null)
                __processData(props.caseData.caseSummaryDataRows)
            }
        }
    }, [props])


    const __processData = (data: any) => {
        // 
    }




    const __renderTableHeader = (data: any) => {
        return <tr>{
            data.map((res: any, index: number) => {
                return <th key={index} className="th" style={{ width: res?.width !== 0 ? res.width : 'auto' }}
                ><p>{res.displayName === '' ? res.name : res.displayName}</p></th>
            })
        }</tr>
    }

    const __renderDataRows = (data: any, index: number) => {
        return <tr key={index}>{data.values.map((tdData: any, index: number) => {
            let columnData = columnsData[index];
            return <td className="td"><p>{parseDataByType(tdData, columnData?.dataType)}</p></td>
        })}
        </tr>
    }


    const __renderTableBody = () => {
        return <table className="data-grid" style={{ width: "100%" }}>
            <thead className="thead">
                {columnsData !== null && __renderTableHeader(columnsData)}
            </thead>
            <tbody className="tbody">
                {rowsData !== null && rowsData.map((res: any, index: number) => {
                    return __renderDataRows(res, index)
                })}
            </tbody>
        </table>;
    }


    return (
        <div className="p-2">
            {(emptyMessage !== null && emptyMessage !== "") ? <div className="p-2">{emptyMessage}</div> : __renderTableBody()}
        </div>
    )
}

export default DataGrid;