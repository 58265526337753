import React, { useEffect, useRef, useState } from "react";
import { selectFieldProps } from "../../interfaces";
import { title } from "process";

const DropDown: React.FC<selectFieldProps> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // Ensure selectedValue is always an array to support multiple selections
  const [selectedValue, setSelectedValue] = useState<any[]>([]);
  const [data, setData] = useState<any[]>(props.data || []);
  const [dropdownStyle, setDropdownStyle] = useState({});
  const [isSelectAll, setSelectAll] = useState(false);
  const dropdownRef = useRef<HTMLUListElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setData(props.data || []);
  }, [props.data]);

  useEffect(() => {
    if (props.defaultSelected !== null && props.defaultSelected !== undefined && props.defaultSelected !== "") {

      let defaultSelected: any = props.defaultSelected;

      if (defaultSelected !== undefined) {

        if (props.isMultiSelectPickList) {
          defaultSelected = defaultSelected.split(", ");
        }

        const selectedItems = props.data.filter((item: any) => {
          return defaultSelected.includes(item.value)
        });

        if (selectedItems.length > 0) {
          setSelectedValue([...selectedItems]);
        }
      }
    } else {
      setSelectedValue([]);
      setSelectAll(false)
    }
  }, [props.defaultSelected]);

  const __openDropdown = () => {
    setIsOpen(!isOpen);
  };

  const __selectOption = (e: any, item: any) => {
    e.preventDefault();

    if (props.isMultiSelectPickList) {
      // Check if item is already selected
      const index = selectedValue.findIndex((res) => res.value === item.value);
      
      if (index > -1) {
        // Remove item if already selected
        props.onChange(selectedValue.filter((_, i) => i !== index));
      } else {
        // Add item to selected values

        if (item.value === "all" && !isSelectAll) {
          props.onChange(data);
          setIsOpen(false);
          setSelectAll(true);
        } else if (item.value === "all" && isSelectAll) {
          props.onChange([]);
          setIsOpen(false);
          setSelectAll(false);
        } else {
          props.onChange([...selectedValue, item]);
          setSelectAll(false);
        }
      }
    } else {
      if (item.id === selectedValue[0]?.id) {
        props.onChange([]);
      } else {
        props.onChange([item]);
      }

      setIsOpen(false);
    }
  };


  useEffect(() => {
    if (isOpen) {
      const dropdownRect = dropdownRef.current?.getBoundingClientRect();
      const buttonRect = buttonRef.current?.getBoundingClientRect();
      if (dropdownRect && buttonRect) {
        const isOverflowingBottom = dropdownRect.bottom > window.innerHeight;
        const isOverflowingRight = dropdownRect.right > window.innerWidth;

        setDropdownStyle({
          top: isOverflowingBottom ? '100%' : '100%',
          bottom: isOverflowingBottom ? '100%' : 'auto',
          left: isOverflowingRight ? 'auto' : '0',
          right: isOverflowingRight ? '0' : 'auto',
        });
      }
    }
  }, [isOpen]);

  return (
    <>
      <div className="filter-fields">
        {props.labelExternal && (
          <>
            <h3 className="title">{props.label}</h3>
          </>
        )}
        <div className={isOpen ? "select-container open" : "select-container"} data-ismultiselect={props.isMultiSelectPickList}>
          <div className="select-button-wrapper">

            <button ref={buttonRef} onClick={__openDropdown} type="button">
              {/* Render selected item titles or default label if none */}
              {isSelectAll ? 
              "All" : selectedValue.length
                ? selectedValue.map((val) => val.title).join(", ")
                : "Select an option"}
            </button>

            {/* {
              __renderClearButton()
            } */}

          </div>

          {isOpen && (
            <div className="ul-wrapper">
              <ul ref={dropdownRef} className="dropdown-list" style={dropdownStyle}>
                {
                  props.isMultiSelectPickList ? <li
                    key={"all"}
                    className={ "item" }
                  >
                    <button onClick={(e) => __selectOption(e, { id: "all", title: "All", value: "all" })} type="button">All</button>
                  </li> : null
                }


                {data.length ? (
                  data.map((item, index) => {
                    return <li
                      key={index}
                      className={
                        isSelectAll || selectedValue.find((val) => val.value === item.value) ? "item active" : "item"
                      }
                    >
                      <button onClick={(e) => __selectOption(e, item)} type="button">
                        {props.isMultiSelectPickList ? <span className="checkbox" /> : null}
                        <span className={props.isMultiSelectPickList ? "px-2" : ""}>{item.title}</span>
                      </button>
                    </li>
})
                ) : (
                  <li className="item">Fetching...</li>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>

      {isOpen && (
        <div
          onClick={__openDropdown}
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10000,
          }}
        ></div>
      )}
    </>
  );
};

export default DropDown;