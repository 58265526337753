import * as type from './types';
/**
 * Dispatches an action to Login.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const fetchCaseConfigByDSPId = (params: any) => {
    return { type: type.CASE_CONFIG_REQUEST, payload: params }
}


/**
 * Resets the state of Login Data.
 *
 * @returns {object} - The action object.
 */
export const resetCaseConfigByDSPId = () => {
    return { type: type.RESET_CASE_CONFIG, payload: null }
}

/**
 * Dispatches an action to Login.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const fetchDSPId = (params: any) => {
    return { type: type.GET_DSP_REQUEST, payload: params }
}


/**
 * Resets the state of Login Data.
 *
 * @returns {object} - The action object.
 */
export const resetFetchDSPId = () => {
    return { type: type.RESET_GET_DSP, payload: null }
}


/**
 * Dispatches an action to Login.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const fetchPicklistById = (params: any) => {
    return { type: type.GET_PICKLIST_REQUEST, payload: params }
}


/**
 * Resets the state of Login Data.
 *
 * @returns {object} - The action object.
 */
export const resetFetchPicklistById = () => {
    return { type: type.RESET_GET_PICKLIST, payload: null }
}


/**
 * Dispatches an action to Login.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const fetchGridDataById = (params: any) => {
    return { type: type.GET_DATA_GRID_REQUEST, payload: params }
}


/**
 * Resets the state of Login Data.
 *
 * @returns {object} - The action object.
 */
export const resetFetchGridDataById = () => {
    return { type: type.RESET_GET_DATA_GRID, payload: null }
}



/**
 * Dispatches an action to Login.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const fetchChildGridDataById = (params: any) => {
    return { type: type.GET_CHILD_GRID_REQUEST, payload: params }
}


/**
 * Resets the state of Login Data.
 *
 * @returns {object} - The action object.
 */
export const resetFetchChildGridDataById = () => {
    return { type: type.RESET_GET_CHILD_GRID, payload: null }
}


/**
 * Dispatches an action to Login.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const deleteRecordById = (params: any) => {
    return { type: type.DELETE_DATA_BY_ID_REQUEST, payload: params }
}


/**
 * Resets the state of Login Data.
 *
 * @returns {object} - The action object.
 */
export const resetDeleteRecordById = () => {
    return { type: type.RESET_DELETE_DATA_BY_ID, payload: null }
}


/**
 * Dispatches an action to Login.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const editCaseRecordById = (params: any) => {
    return { type: type.PUT_CASE_SUMMARY_REQUEST, payload: params }
}


/**
 * Resets the state of Login Data.
 *
 * @returns {object} - The action object.
 */
export const resetEditCaseRecordById = () => {
    return { type: type.RESET_PUT_CASE_SUMMARY, payload: null }
}

/**
 * Dispatches an action to create case api.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const fetchCreateCase = (params: any) => {
    return { type: type.CREATE_CASE_REQUEST, payload: params }
}


/**
 * Resets the state of Create case Data.
 *
 * @returns {object} - The action object.
 */
export const resetCreateCase = () => {
    return { type: type.RESET_CREATE_CASE, payload: null }
}



/**
 * Dispatches an action to create case api.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const searchCase = (params: any) => {
    return { type: type.SEARCH_CASE_REQUEST, payload: params }
}


/**
 * Resets the state of Create case Data.
 *
 * @returns {object} - The action object.
 */
export const resetSearchCase = () => {
    return { type: type.RESET_SEARCH_CASE, payload: null }
}


/**
 * Dispatches an action to create case api.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const addNewCase = (params: any) => {
    return { type: type.ADD_NEW_CASE_REQUEST, payload: params }
}


/**
 * Resets the state of Create case Data.
 *
 * @returns {object} - The action object.
 */
export const resetAddNewCase = () => {
    return { type: type.RESET_ADD_NEW_CASE, payload: null }
}


