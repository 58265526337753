import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { pickListDataPopup } from "../store/actions";
import { useEffect } from "react";

const EngagmentFilterSelectPicker = (props: any) => {
    return (
        <div className="column-container" key={props.items.controlId}>
            <div className="involve-case-container">
                <select
                    className="involve-case"
                    name={props.items.name}
                    key={props.items.controlId}
                    onChange={props.handleChange}
                    value={props.formData[props.items.name]}
                    id={props.items.controlId}
                >
                    {props.items.picklistValues?.map((option: any) => (
                        <option value={option.valueToStore} key={option.valueToStore}>
                            {option.textToDisplay == "" ? props.items.label : option.textToDisplay}
                        </option>
                    ))}
                </select>
            </div>
        </div>)
}
export default EngagmentFilterSelectPicker;