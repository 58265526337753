// Import React hooks and components
import { useEffect, useState } from "react"
import { List, ListRow, Tabs, Summary } from "."

import { Wrapper } from "../../../components"


/**
 * Layout component to handle the layout of various sections.
 *
 * @param {any} props - Data received from the parent component.
 * @returns {JSX.Element} - Rendered component.
 */
const Layout: any = (props: any) => {
        // State to store primary sections
        const [section, setSection] = useState<any>([])
        const [headerStyle, setHeaderStyle] = useState<any>([])

        // useEffect to update the primary section when props change
        useEffect(() => {
                if (props.data !== null) {
                        setSection(props.data.primarySections)
                        setHeaderStyle({
                                headerOpenColour: props.data.headerOpenColour,
                                headerClosedColour: props.data.headerClosedColour,
                                headerOpenFontColour: props.data.headerOpenFontColour,
                                headerClosedFontColour: props.data.headerClosedFontColour
                        })
                }

        }, [props])

        /**
         * Helper function to layout different sections based on their type.
         *
         * @param {any} res - Section data.
         * @returns {JSX.Element|null} - Rendered JSX based on the section type.
         */
        const __layout = (res: any) => {
                let renderItems: any = null;
                switch (res.type) {
                        case "list":
                                let caseData = null;
                                if (props.caseData !== null && props.caseData.hasOwnProperty(res.id)) {
                                        caseData = props.caseData[res.id];
                                }

                                renderItems = <>
                                        <Summary data={caseData} />
                                        <List key={res.key}
                                                isPrepulate={res.prePopulate}
                                                data={res}
                                                caseData={caseData}
                                        />
                                </>
                                break;
                        case "listrow":
                                let caseDataRow = null;
                                if (props.caseData !== null && props.caseData.hasOwnProperty(res.id)) {
                                        caseDataRow = props.caseData[res.id];
                                }

                                renderItems = <>
                                        <Summary data={caseDataRow} />
                                        <ListRow key={res.key}
                                                isPrepulate={res.prePopulate}
                                                data={res}
                                                caseData={caseDataRow}
                                        />
                                </>
                                break;
                        case "tabs":
                                renderItems = <>
                                        {/* <Summary data={props.caseData} /> */}
                                        <Tabs
                                                key={res.key}
                                                tabs={res.subSections}
                                                activeTab={res?.defaultSelectedTabId !== '' ? parseInt(res?.defaultSelectedTabId) : res?.subSections[0]?.id}

                                                caseData={props.caseData}
                                                onClickTab={(id: string) => {
                                                        props.onClickFetchCaseData(id)
                                                }}


                                        />
                                </>
                                break;
                        default:
                                renderItems = <div className="section">{res.type}</div>
                }

                return renderItems;
        }

        // Main component render
        return <>
                {
                        section.map((res: any, index: number) => {
                                let isOpen = false;

                                if (props.isAside && res.open === 1 && index === 0) {
                                        isOpen = true;
                                }
                                return (<Wrapper

                                        sectionId={res.id}
                                        showFeedBackButton={true}
                                        key={res.id}
                                        title={res.title}
                                        id={res.id}
                                        isOpen={isOpen}
                                        iconImage={res.iConUrl}
                                        isAside={props.isAside}
                                        headerStyle={{ headerOpenColour: headerStyle.headerOpenColour, headerClosedColour: headerStyle.headerClosedColour }}
                                        headerFontStyle={{ headerOpenFontColour: headerStyle.headerOpenFontColour, headerClosedFontColour: headerStyle.headerClosedFontColour }}
                                        onClickExpand={() => {
                                                if (res?.subSections[0]?.id !== undefined) {

                                                        let id = res?.subSections[0]?.id;
                                                        props.onClickFetchCaseData(id)
                                                }
                                        }}>
                                        {__layout(res)}
                                </Wrapper>);
                        })
                }
        </>
}

// Export the Layout component
export default Layout