import { useEffect, useState } from "react";
import { InformationGovernance, useLegacySupport } from "../../components";
import { Layout } from "./components";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchCaseSummaryConfig, fetchCaseSummaryData, fetchHHC, fetchInformationGovernanceData, fetchOverviewreference, resetCaseSummaryConfig, resetCaseSummaryData, resetFetchOverviewreference, resetFetchOverviewreferenceAccess, resetInformationGovernanceData, updateData } from "./store/actions";
import { infinityLoader } from "../../config/Images";
import caseSummaryService from "./services/case-summary-service";
import { getData, GROUP_REF_SELETECTED, setData } from "../../utils/Storage";

const CaseSummaryCM = (props: any) => {
  const dispatch = useDispatch()

  // Redux state variables
  const informationGovernanceData: any = useSelector<any>((state) => state.informationGovernanceDataCM);
  const overviewreference: any = useSelector<any>((state) => state.overviewreferenceCM);
  const overviewreferenceaccess: any = useSelector<any>((state) => state.overviewreferenceaccessCM);
  const caseSummaryConfig: any = useSelector<any>((state) => state.caseSummaryConfigCM);
  const caseSummaryData: any = useSelector<any>((state) => state.caseSummaryDataCM);
  const caseData: any = useSelector<any>((state) => state.caseDataCM);

  // Local state variables
  const [token, setToken] = useState<any>(null);
  const [informationGovernanceModel, setInformationGovernanceModel] = useState<Boolean>(true);
  const [isIGAccess, setIGAccess] = useState<Boolean>(false);
  const [informationGovernanceDataState, setInformationGovernanceDataState] = useState<any>(null);
  const [onveViewReferenceId, setOneViewreferenceId] = useState<any>(props.selectedCaseReference);

  // Component state variables
  const [demographicsData, setDemographicsData] = useState<any>(null);
  const [mainSectionData, setMainSectionData] = useState<any>(null);
  const [caseConfigDataState, setCaseConfigDataState] = useState<any>(null);
  const [caseDataState, setCaseDataState] = useState<any>(null);
  const [footer, setFooter] = useState<any>(null);

  const legacyToken = useLegacySupport();

  useEffect(() => {
    // Fetch Information Governance data
    if (legacyToken.token !== null) {
      setToken(legacyToken.token);
      setOneViewreferenceId(props.selectedCaseReference);

      getData(GROUP_REF_SELETECTED).then((res: any) => {
        if (res !== null && !res.infogov) {
          dispatch(fetchInformationGovernanceData({ token: legacyToken.token, dspId: props.dsp, caseReference: props.selectedCaseReference }));
        }else {
          dispatch(fetchOverviewreference(res));
        }
      })
    }
  }, [])

  useEffect(() => {
    // Process received Information Governance data
    try {
      if (informationGovernanceData.isSuccess && informationGovernanceData.data !== null) {
        setInformationGovernanceDataState(informationGovernanceData.data);

        dispatch(resetInformationGovernanceData());
      } else if (informationGovernanceData.isError) {
        toast.error(informationGovernanceData?.data?.error?.data);
        dispatch(resetInformationGovernanceData());
      }
    } catch (e: any) {
      toast.error(e.message);
    }
  }, [informationGovernanceData]);


  useEffect(() => {
    // Process received overview references
    if (overviewreference.isSuccess && overviewreference.data !== null) {

      let data = overviewreference.data;

      if (data.accessCaseSummaryFlag === 1) {
        setInformationGovernanceModel(false);

        dispatch(fetchHHC({
          token: token, params: {
            oneviewReference: props.selectedCaseReference,
            dspId: props.dsp,
          }
        }));

        dispatch(fetchCaseSummaryConfig({
          caseSummaryType: data.caseSummaryTypeId,
          dspId: props.dsp,
          token: token,
        })
        );
      } else {
        toast.error(data.responseMessage);
      }

      dispatch(resetFetchOverviewreference());
    } else if (overviewreference.isError) {
      // toast.error(overviewreference.data.error.data.title);
      dispatch(resetFetchOverviewreference());
    }
  }, [overviewreference]);


  useEffect(() => {
    // Fetch case data if prepopulate is true
    if (isIGAccess) {
      caseConfigDataState.forEach((item: any) => {
        let primarySections = item.primarySections;
        let cases: string[] = [];
        if (primarySections && primarySections.length > 0) {
          primarySections.forEach((section: any) => {
            if (section.prePopulate === "Y") {
              if (section.type === "tabs") {
                section.subSections.forEach((tab: any) => {
                  if (tab.prePopulate === "Y") {
                    // __fetchCaseDataAPI(tab.id, overviewReferenceId);
                    cases.push(tab.id);
                  }
                });
              } else {
                // __fetchCaseDataAPI(section.id, overviewReferenceId);
                cases.push(section.id);
              }
            }
          });
        }

        if (cases.length > 0) {
          __fetchAllCasesDataAPI(cases, onveViewReferenceId);
        }
      });
    }
  }, [isIGAccess]);


  useEffect(() => {
    if (
      overviewreferenceaccess.isSuccess &&
      overviewreferenceaccess.data !== null
    ) {
      let data = overviewreferenceaccess.data;
      if (data.accessCaseSummaryFlag === 1) {

        dispatch(
          fetchCaseSummaryConfig({
            caseSummaryType: data.caseSummaryTypeId,
            token: token,
          })
        );
      } else {
        toast.error(data.responseMessage);
      }

      dispatch(resetFetchOverviewreferenceAccess());
    } else if (overviewreferenceaccess.isError) {
      toast.error(
        overviewreferenceaccess.data.error.data ||
        overviewreferenceaccess.data.error.status
      );
      dispatch(resetFetchOverviewreferenceAccess());
    }
  }, [overviewreferenceaccess]);

  useEffect(() => {
    // Process received case data
    try {
      if (caseData.data && caseData.data !== null) {

        setCaseDataState(caseData.data);
      }
    } catch (e: any) {
      toast.error(e.message);
    }
  }, [caseData]);

  useEffect(() => {
    // Process received case summary config data
    try {
      if (caseSummaryConfig.isSuccess && caseSummaryConfig.data !== null) {

        const data = caseSummaryConfig.data.panels;
        if (data && data.length > 0) {
          setCaseConfigDataState(data);
          setIGAccess(true);

          data.forEach((item: any) => {
            switch (item.title) {
              case "Demographics":
                setDemographicsData(item);
                break;

              case "Data sections":
                setMainSectionData(item);
                break;

              case "":
                setMainSectionData(item);
                break;

              default:
                return "none";
            }
          });
        }

        const footer = caseSummaryConfig.data?.footer;
        if (footer) {
          setFooter(footer);
        }

        dispatch(resetCaseSummaryConfig());
      } else if (caseSummaryConfig.isError) {
        toast.error(caseSummaryConfig.data.error.data);
        dispatch(resetCaseSummaryConfig());
      }
    } catch (e: any) {
      toast.error(e.message);

      dispatch(resetCaseSummaryConfig());
    }
  }, [caseSummaryConfig]);

  useEffect(() => {
    // Process received case summary data
    try {
      if (caseSummaryData.isFetching) {
        dispatch(updateData(caseSummaryData.data));
      } else if (caseSummaryData.isSuccess && caseSummaryData.data !== null) {

        // update the case data

        let data = caseSummaryData.data;

        if (data?.primarySectionId) {
          dispatch(
            updateData({ dataId: data.primarySectionId, data: caseSummaryService.processDataForKendoGridList(data) })
          );
        } else if (typeof data && data.length > 0) {
          data.map((res: any) => {
            dispatch(
              updateData({
                dataId: res.primarySectionId,
                data: caseSummaryService.processDataForKendoGridList(res),
              })
            );
          })
        }

        // reset the case summary data API
        dispatch(resetCaseSummaryData());
      } else if (caseSummaryData.isError) {
        toast.error(caseSummaryData.data.error.data);
        dispatch(updateData(caseSummaryData));
        dispatch(resetCaseSummaryData());
      }
    } catch (e) {
      dispatch(resetCaseSummaryData());
    }
  }, [caseSummaryData]);


  // Fetch case summary data
  const __fetchAllCasesDataAPI = (dataId: string[], caseId: string) => {
    try {
      dispatch(
        fetchCaseSummaryData({
          dataId: dataId.join(","),
          caseId: caseId,
          token: token,
          fetchSelected: true,
        })
      );
    } catch (e: any) {
      toast.error(e.message);
    }
  };


  // Fetch case summary data
  const __fetchCaseDataAPI = (dataId: string, caseId: string) => {
    try {
      if (caseData.data[dataId] === undefined || caseData.data[dataId] === null) {
      dispatch(
        fetchCaseSummaryData({
          dataId: dataId,
          caseId: caseId,
          token: token,
          fetchSelected: false,
        })
      );
    }
    } catch (e: any) {
      toast.error(e.message);
    }
  };


  // Generate PDF
  // const __printPDF = (title: string) => {
  //   const doc = new jsPDF({
  //     unit: "px",
  //     format: [window.innerWidth * 1.41, window.innerWidth],
  //   });

  //   dispatch(preparePDF(true));
  //   alert("Pleas wait while we are preparing PDF");
  //   setTimeout(() => {
  //     doc.html(document.body, {
  //       callback: function (doc) {
  //         let fileName = toSeoUrl(
  //           (caseReference || onveViewReferenceId) + "-" + title
  //         );
  //         doc.save(fileName + ".pdf");

  //         dispatch(preparePDF(false));
  //         alert("PDF downloaded successfully");
  //       },
  //       x: 10,
  //       y: 10,
  //     });
  //   }, 500);
  // };

  // Submit IG Plugin Authentication
  const __submitIgPluginAuth = (type: string, data: any) => {
    try {
      if (type === "closeForm") {
        window.close();
        setInformationGovernanceModel(false);
      } else if (type === "formSubmit") {

        /**
         * Dispatch action to fetch IG data
         **/

        if (data.involveCase !== null) {

          let concentRequire = false;
          let dspRoleId = null

          Object.keys(data.involveCase).forEach((item: any) => {
            if (data.involveCase[item].userConsent === true && data.involveCase[item].id === "No") {
              toast.error("No access to case summary");
              concentRequire = true;
            }
          })

          if (!concentRequire) {
            let overviewRefReq = {
              caseReference: props.selectedCaseReference,
              token: token,
              dspRoleId: data.involveCase[4].id,
              dspId: props.dsp,
              url: data.url,
              infogov: true,
            };

            setData(GROUP_REF_SELETECTED, overviewRefReq)

            /**
             * Dispatch action to fetch overview reference
             **/
            dispatch(fetchOverviewreference(overviewRefReq));
          }
        }
      }
    } catch (e: any) {
      toast.error(e.message);
    }
  };


  // Render aside layout
  const __renderAside = () => {
    if (caseSummaryConfig.isFetching) {
      return <div style={{ textAlign: "center" }}><img src={infinityLoader} alt="loading" style={{ width: 40 }} /></div>;
    }

    return (
      <Layout
        data={demographicsData}
        caseData={caseDataState}
        isAside={true}
        onClickExpand={(id: string) => {
          console.log(id);
        }}
        onClickFetchCaseData={(dataId: string) => {
          __fetchCaseDataAPI(dataId, onveViewReferenceId);
        }}
      />
    );
  };



  // Render main layout
  const __renderMain = () => {
    if (caseSummaryConfig.isFetching) {
      return <div style={{ textAlign: "center" }}><img src={infinityLoader} alt="loading" style={{ width: 40 }} /></div>;
    }

    return (
      <>
        {
          mainSectionData?.primarySections.length > 0 ? (
            <Layout
              data={mainSectionData}
              caseData={caseDataState}
              isAside={false}
              onClickExpand={(id: string) => {
                console.log(id);
              }}
              onClickFetchCaseData={(dataId: string) => {
                __fetchCaseDataAPI(dataId, onveViewReferenceId);
              }}
            />) :

            <div className="no-data " >
              <p>{mainSectionData.emptyPanelMessage}</p>
            </div>

        }

        {footer ? (
          <div>
            <p style={footer.footerStyle}>{footer.text}</p>
          </div>
        ) : null}
      </>
    );
  };



  return (
    <div>
      {caseSummaryConfig.isFetching ? <div style={{ textAlign: "center" }}><img src={infinityLoader} alt="loading" style={{ width: 40 }} /></div> : null}

      {caseConfigDataState !== null ? (
        __renderMain()
      ) : (
        <div style={{ textAlign: "center" }}><img src={infinityLoader} alt="loading" style={{ width: 40 }} /></div>
      )}

      {informationGovernanceModel &&
        informationGovernanceDataState &&
        informationGovernanceDataState !== null ? (
        <InformationGovernance
          data={informationGovernanceDataState}
          isFetching={overviewreference.isFetching}
          onSubmit={(type: string, res: any) => {
            if (res === null) {
              props.onCaseClose()
            } else {
              __submitIgPluginAuth(type, res)
            }

          }}
        />
      ) : null}


    </div>
  )
}

export default CaseSummaryCM;