import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { closeIcon } from "../../../assets/icons";
import { Button } from "../../../components";
import moment from "moment";
import { infinityLoader } from "../../../config/Images";
import { t } from "i18next";
import { Upload } from "@progress/kendo-react-upload";

import { getHeaders } from "../../../utils/commonUtils";


// Functional component for Information Governance
const AddNotePopup = (props: any) => {

    const uploadRef = useRef(null);
    const fileTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel"
    ];


    // State for storing the "involve case" data
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [files, setFiles] = useState<any>([]);

    // State for storing form data
    const formObj: any = {};

    props?.data?.popupForm?.controls?.forEach((element: any) => {
        formObj[element.name] = element?.value === undefined ? "" : element.name === "ActionDate" ? moment(element.value, 'MM/DD/YYYY h:mm:ss A').format('DD/MM/YYYY') : element.value;
    });

    const [formData, setFormData] = useState<any>(formObj);
    // Function to call API and handle redirection
    const callApiAndRedirect = (e: any): void => {
        if (Object.values(formData).some(a => a !== '')) {
            setIsFetching(true);
            props.onSubmit("formSubmit", formData);
        } else {
            toast.error("Please select values");
        }
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        if (props.isFetching !== isFetching) {
            setIsFetching(props.isFetching);
        }
    }, [props.isFetching]);

    // Function to render layout based on item type
    const __renderLayout = (items: any, index: any) => {
        let itemsToRender = [];
        switch (items.type) {
            case "text":
                if (items.visible === true && props.isUpload === false) {
                    itemsToRender.push(
                        <div hidden={!items.visible} className="column">
                            <h6 className="involve-case-title">{items.label}</h6>
                            <input disabled={!items.isEditable}
                                name={items.name}
                                type={items.type}
                                value={formData[items.name]}
                                onChange={handleChange}
                            ></input>
                        </div>
                    );
                }
                break;
            case "file":
                if (items.visible === true && props.isUpload === true) {
                    itemsToRender.push(
                        <div hidden={!items.visible} className="column">
                            <h6 className="involve-case-title">{items.label}</h6>
                            {/* <ExternalDropZone uploadRef={uploadRef} /> */}

                            <div>
                                <input type="file" name={items.name} onChange={(e) => {
                                    const file = e.target.files;

                                    // check extension of file to be uploaded
                                    if (file && !fileTypes.includes(file[0].type)) {
                                        toast.error("Please upload a valid file");
                                        return;
                                    }


                                    if (file) {
                                        const fileReader = new FileReader();
                                        fileReader.onload = (e) => {
                                            setFormData({ ...formData, [items.name]: fileReader.result });
                                        };
                                        fileReader.readAsDataURL(file[0]);

                                        setFiles([...files, {
                                            attachment: file[0], uploaded: false, name: file[0].name, message: ""
                                        }]);
                                    }
                                }} />
                                <p className="text-secondary">Allowed files .jpeg, .jpg, .png, .doc, .docx, .xls, .xlsx, .pdf, .tif</p>
                            </div>

                            <div className="fileNames">
                                {
                                    files.map((file: any, index: any) => {
                                        return (
                                            <div key={index}>
                                                <p className={file.uploaded ? "text-success" : ""}><span>{file.name}</span>
                                                    <button
                                                        className="btn btn-danger btn-sm mx-3"
                                                        onClick={() => {
                                                            const newFiles = files.filter((item: any) => item.name !== file.name);
                                                            setFiles(newFiles);
                                                        }}>Remove</button>
                                                </p>
                                                <p>{file.message !== "" ? file.message : ""}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div>
                                <button className="btn btn-primary" type={"button"} onClick={() => {
                                    if (files.length === 0) {
                                        toast.error("Please upload a file");
                                        return;
                                    }


                                    files.map((file: any, index: any) => { 
                                        const fmData = new FormData();
                                        fmData.append("file", file.attachment);
                                        if (file.uploaded) {
                                            toast.error(`${file.attachment.name} already uploaded`);
                                            return;
                                        }

                                        fetch(props.uploadURL, {
                                            method: "POST",
                                            headers: {
                                                ...getHeaders(props.token)
                                            },
                                            body: fmData,
                                        }).then((response) => {
                                            if (response.ok) {
                                                toast.success("Files uploaded successfully");
                                                const tempFiles = [...files];
                                                tempFiles[index].uploaded = true;
                                                tempFiles[index].message = response.statusText;
                                                setFiles(tempFiles);
                                            } else {
                                                toast.error("Error in uploading files");
                                                const tempFiles = [...files];
                                                tempFiles[index].uploaded = false;
                                                tempFiles[index].message = response.statusText;
                                                setFiles(tempFiles);
                                            }
                                        }).catch((error) => {
                                            toast.error("Error in uploading files");
                                        });

                                    });
                                }}>
                                    Upload all
                                </button>
                            </div>

                            {/* <Upload
                                ref={uploadRef}
                                // restrictions={{
                                //     allowedExtensions: [".jpeg", ".jpg", ".png", ".doc", ".docx", ".xls", ".xlsx", ".pdf", ".tif"],
                                // }}
                                
                                autoUpload={true}
                                batch={false}
                                multiple={true}
                                defaultFiles={[]}
                                withCredentials={false}
                                saveHeaders={getHeaders(props.token)}
                                saveUrl={props.uploadURL}

                            /> */}
                        </div>
                    );
                }

                break;
            default:
                return null;
        }

        return itemsToRender;

    };

    const renderFooter = () => {
        return (
            <div className="modal-footer">
                <Button
                    key={""}
                    title={"Cancel"}
                    className="button cancel-button"
                    type="button"
                    onClick={() => props.onSubmit("", null)}

                />
                <Button
                    key={""}
                    title={isFetching ? t("REQUESTING") : props?.data?.popupForm?.controls?.find((item: any) => item.name === "saveButton")?.label || t("SAVE")}
                    name={props?.data?.popupForm?.controls?.find((item: any) => item.name === "saveButton")?.name}
                    className={
                        "button save-button " +
                        (isFetching ? "requesting btn-disable" : "")
                    }
                    type="submit"
                    onClick={callApiAndRedirect}
                    disabled={isFetching}
                />
            </div>
        )
    }

    const renderHeader = () => {
        return (
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                    {t("ADDNOTESPOPUPHEAD")}
                </h5>
                <button className="btn btn-transparent close" onClick={() => {
                    props.onSubmit("", null);
                }} >
                    <img src={closeIcon} alt="close" />
                </button>
            </div>
        )
    }

    const renderBody = () => {
        return (
            <div className="modal-body">
                {props?.data?.popupForm === undefined ? <div style={{ textAlign: "center" }}><img src={infinityLoader} alt="loading" style={{ width: 40 }} /></div> :
                    <form
                        className="information-governance-container"
                        onSubmit={(e) => {
                            e.preventDefault();
                            callApiAndRedirect(e);
                        }}
                    >
                        <div className="content-container">
                            <div className="row">
                                {props?.data?.popupForm?.controls?.map((items: any, index: any) => {
                                    return __renderLayout(items, index);
                                })}
                            </div>
                        </div>
                    </form>}
            </div>
        )
    }


    return (
        <div className="addoutcome-popup">
            <div
                className="modal fade show"
                id="exampleModal"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        {renderHeader()}
                        {renderBody()}
                        {renderFooter()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddNotePopup;
