// Import required modules and configuration
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';

// Import the action types from the constants file
import * as types from "./types";
import { encodeFormData, getNewAxiosInstance } from "../../../utils";
import { bulkActionFailuer, bulkActionRequest, bulkActionSuccess } from './bulkaction.slice';
import { bulkDeleteActionFailuer, bulkDeleteActionRequest, bulkDeleteActionSuccess } from './bulkdeleteaction.slice';
import { bulkConfigActionFailuer, bulkConfigActionRequest, bulkConfigActionSuccess } from './bulkactionconfig.slice';
import { fetchAllPickerListRequest, fetchAllPickerListSuccess, resetFetchAllPickerList } from './fetchAllPickerList.slice';


/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchCaseConfigByDSPId(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        let apiEndPoint = `/case-management/config/${res.payload.dspId}`;

        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, apiEndPoint);
        
            const organization = response.headers.organization;
            const username  = response.headers.username;

        // Dispatch action to update state with received data
        yield put({
            type: types.CASE_CONFIG_RECEIVE,
            payload: {...response.data, organization: organization , username:username }
        });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.CASE_CONFIG_FAILURE, payload: { error: e } });
    }
}


/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchDSPIds(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, `/dsp`);

        // Dispatch action to update state with received data
        yield put({
            type: types.GET_DSP_RECEIVE,
            payload: response.data
        });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.GET_DSP_FAILURE, payload: { error: e } });
    }
}


/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchPickListItemsByID(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        const api = res.payload.endpoint.slice(4);

        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, api, { params: res.payload.param });

        const data = response.data.values.map((item: any, index: number) => {
            return { id: index, title: item.textToDisplay, value: item.valueToStore }
        });

        response.data.values = data;
        response.data.controlId = res.payload.param.controlId;

        // Dispatch action to update state with received data
        yield put({
            type: types.GET_PICKLIST_RECEIVE,
            payload: response.data
        });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.GET_PICKLIST_FAILURE, payload: { error: e } });
    }
}


/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchPickListItemsAll(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        
        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, "dropdownOption/fetch-all?", { params: res.payload.param });

        // const data = response.data.options.map((item: any, index: number) => {
        //     return item.values.map((item: any, index: number) => {
        //         return { id: index, title: item.textToDisplay, value: item.valueToStore }
        //     });
        // })

        // Dispatch action to update state with received data
        yield put(fetchAllPickerListSuccess(response.data));
    } catch (e: any) {

        // Handle failure and update state
        yield put(resetFetchAllPickerList(e.response));
    }
}


/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchGridDataByID(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch case summary data
        // const response: AxiosResponse = yield call(ins.get, "data/main-grid-data", { params: { ...res.payload.param} });
        const response: AxiosResponse = yield call(ins.post, `data/v2/main-grid-data?dspId=${res.payload.param.dspId}&gridId=${res.payload.param.gridId}`, res.payload.param.filtersJson);

        const xPaging = JSON.parse(response.headers["x-pagination"]);

        // Dispatch action to update state with received data
        yield put({
            type: types.GET_DATA_GRID_RECEIVE,
            payload: { ...response.data, paging: xPaging }
        });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.GET_DATA_GRID_FAILURE, payload: { error: e } });
    }
}



/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchChildDataByID(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, "data/child-grid-data", { params: res.payload.param });
        response.data.oneViewReference = res.payload.param.oneViewReference;

        // Dispatch action to update state with received data
        yield put({
            type: types.GET_CHILD_GRID_RECEIVE,
            payload: response.data
        });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.GET_CHILD_GRID_FAILURE, payload: { error: e } });
    }
}


/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* deleteRecordById(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.delete, "data/reset-data", { params: res.payload.param });

        // Dispatch action to update state with received data
        yield put({
            type: types.DELETE_DATA_BY_ID_RECEIVE,
            payload: response.data
        });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.DELETE_DATA_BY_ID_FAILURE, payload: { error: e } });
    }
}



/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* editCaseDetails(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.put, `data/update-data?dspId=${res.payload.params.dspId}&gridId=${res.payload.params.controlId}&oneViewReference=${res.payload.params.oneViewReference}`, res.payload.body);

        // Dispatch action to update state with received data
        yield put({
            type: types.PUT_CASE_SUMMARY_RECEIVE,
            payload: response.data
        });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.PUT_CASE_SUMMARY_FAILURE, payload: { error: e } });
    }
}




/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* fetchCreateCase(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, `case-details/create-case-url/${res.payload.param.dspId}`);

       // console.log("apiREs",response.data)

        // Dispatch action to update state with received data
        yield put({
            type: types.CREATE_CASE_RECEIVE,
            payload: response.data
        });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.CREATE_CASE_FAILURE, payload: { error: e } });
    }
}



/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* searchCaseByKey(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        let param = res.payload.param;

        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, `core-search/all/${param.dspId}/${param.controlId}/${param.searchKey}`);
        // Dispatch action to update state with received data
        yield put({
            type: types.SEARCH_CASE_RECEIVE,
            payload: response.data
        });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.SEARCH_CASE_FAILURE, payload: { error: e } });
    }
}


/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* addNewCaseBySCVId(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        let param = res.payload.param;

        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.post, `case-details/add-selected-case`, param);
        // Dispatch action to update state with received data
        yield put({
            type: types.ADD_NEW_CASE_RECEIVE,
            payload: response.data
        });
    } catch (e: any) {

        // Handle failure and update state
        yield put({ type: types.ADD_NEW_CASE_FAILURE, payload: { error: e } });
    }
}



/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* bulkConfigAction(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);

        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.get, `bulk-action/config/${res.payload.param.dspId}/${res.payload.param.controlId}`);

        // Dispatch action to update state with received data
        yield put(bulkConfigActionSuccess(response.data));
    } catch (e: any) {
        // Handle failure and update state
        yield put(bulkConfigActionFailuer(e.response));
    }
}



/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* submitBulkAction(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);


        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.post, `bulk-action/gov-notify-sms`, res.payload.param);

        // Dispatch action to update state with received data
        yield put(bulkActionSuccess(response.data));
    } catch (e: any) {

        // Handle failure and update state
        yield put(bulkActionFailuer(e.response));
    }
}



/**
 * Fetches case summary data from the API and updates the state.
 *
 * @param {any} res - The response object containing dataId and token.
 */
function* deleteBulkAction(res: any): SagaIterator {
    try {
        // Create axios instance with headers
        let ins = getNewAxiosInstance(res.payload.token);
        let params = encodeFormData(res.payload.param);

        // Perform GET request to fetch case summary data
        const response: AxiosResponse = yield call(ins.delete, `${res.payload.url}?${params}`);

        // Dispatch action to update state with received data
        yield put(bulkDeleteActionSuccess(response.data));
    } catch (e: any) {

        // Handle failure and update state
        yield put(bulkDeleteActionFailuer(e.response));
    }
}


/**
 * The main saga watcher. Will fork all other sagas into the middleware.
 */
export default function* dashboardSVOD() {
    yield takeLatest(types.CASE_CONFIG_REQUEST, fetchCaseConfigByDSPId);
    yield takeLatest(types.GET_DSP_REQUEST, fetchDSPIds);
    yield takeLatest(types.GET_DATA_GRID_REQUEST, fetchGridDataByID);
    yield takeLatest(types.GET_CHILD_GRID_REQUEST, fetchChildDataByID);
    yield takeLatest(types.DELETE_DATA_BY_ID_REQUEST, deleteRecordById);
    yield takeLatest(types.PUT_CASE_SUMMARY_REQUEST, editCaseDetails);
    yield takeEvery(types.GET_PICKLIST_REQUEST, fetchPickListItemsByID);
    yield takeEvery(fetchAllPickerListRequest.type, fetchPickListItemsAll);
    yield takeLatest(types.CREATE_CASE_REQUEST, fetchCreateCase);
    yield takeLatest(types.SEARCH_CASE_REQUEST, searchCaseByKey);
    yield takeLatest(types.ADD_NEW_CASE_REQUEST, addNewCaseBySCVId);
    yield takeLatest(bulkConfigActionRequest.type, bulkConfigAction);
    yield takeLatest(bulkActionRequest.type, submitBulkAction);
    yield takeLatest(bulkDeleteActionRequest.type, deleteBulkAction);
}