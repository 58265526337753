import HTMLParse from 'html-react-parser';
import moment from 'moment';

const linkGenrator = (data: any) => {
    const arr = data.split("|");
    const link = arr[1];
    const name = arr[0];
    return { name, link };
}

export const parseDataByType = (data: any, type: string) => {

    if (data === null) {
        return ""
    }

    switch (type) {
        case "date":
            return moment(data).format('DD/MM/YYYY');

        case "currency":
            return data !== "" ? `£ ${data}` : "";

        case "attachment":
            if (data === "") {
                return ""
            }

            const tempData = data.split(",");
            let links: any;

            if (tempData.length > 1) {
                links = tempData.map((item: any) => {
                    return linkGenrator(item);
                });
            } else {
                links = [linkGenrator(data)];
            }

            return links;

        case "text":
            return HTMLParse(data);

        default:
            return data;
    }
}