import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { pickListEngagmentDataPopup, resetPickEngagmentListData } from "../store/actions";
import { useEffect, useState } from "react";


const EngagmentSelectPicker = (props: any) => {
    const dispatch = useDispatch();
    const pickList = useSelector((state: any) => state.selectEngagmentPickCM);
    const [data, setData] = useState([])
    const pickListFetching = useSelector((state: any) => state.selectEngagmentPickCM.isFetching);
    const [isChild, setIsChild] = useState<boolean>(false);

    useEffect(() => {
        // Fetch Information pickList

        try {
            if (props.isChild === null || props.isChild === undefined) {
                dispatch(
                    pickListEngagmentDataPopup({
                        token: props.token,
                        param: {
                            dspId: props.dspId,
                            parentPickListSelectedValue: '',
                            controlId: props.items.controlId,
                        },
                        url: props.items.apiPicklistEndpoint.endpointUrl.replace("/api", ""),
                    })
                );
            } else {
                const childData = props.isChild.find((element: any) => parseInt(element) === props.items.controlId);


                console.log(childData)


                if (childData !== undefined) {
                    setIsChild(true);
                } else {
                    dispatch(
                        pickListEngagmentDataPopup({
                            token: props.token,
                            param: {
                                dspId: props.dspId,
                                parentPickListSelectedValue: '',
                                controlId: props.items.controlId,
                            },
                            url: props.items.apiPicklistEndpoint.endpointUrl.replace("/api", ""),
                        })
                    );
                }
            }


        } catch (e: any) {
            toast.error(e.message);
        }
    }, []);

    useEffect(() => {
        // Fetch Information pickList
        try {
            if (isChild && props?.formData?.CommitmentPresentingNeed !== undefined) {
                dispatch(
                    pickListEngagmentDataPopup({
                        token: props.token,
                        param: {
                            dspId: props.dspId,
                            parentPickListSelectedValue: props?.formData?.CommitmentPresentingNeed == undefined ? "" : props?.formData?.CommitmentPresentingNeed,
                            controlId: props.items.controlId,
                        },
                        url: props.items.apiPicklistEndpoint.endpointUrl.replace("/api", ""),
                    })
                );
            }
        } catch (e: any) {
            toast.error(e.message);
        }
    }, [props?.formData?.CommitmentPresentingNeed, isChild]);

    useEffect(() => {
        if (pickList.isSuccess && pickList.data !== null && props.items.controlId === pickList.data.controlId) {
            setData(pickList.data.values);
            dispatch(resetPickEngagmentListData())
        } else if (pickList.isError) {
            dispatch(resetPickEngagmentListData())
        }

    }, [pickList])

    return (
        <div className="column" key={props.items.controlId}>
            <h6 className="involve-case-title">{props.items.label}</h6>
            <div className={isChild == true && pickListFetching ? "involve-case-fetch-container" : "involve-case-container"}>
                <select
                    // disabled={!props.items.isEditable}
                    disabled={props.formData.CommitmentPresentingNeed === "0" && isChild ? true : false}
                    className="involve-case"
                    name={props.items.name}
                    key={props.items.controlId}
                    onChange={props.handleChange}
                    value={props.formData[props.items.name]}
                >
                    {data.map((option: any) => (
                        <option value={option.valueToStore} key={option.valueToStore}>
                            {option.valueToStore === "" || option.valueToStore === "0" ? "Select" : option.textToDisplay}
                        </option>
                    ))}
                </select>
            </div>
        </div>)
}
export default EngagmentSelectPicker;