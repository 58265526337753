import React from "react";

interface popupProps {
  header: any;
  content: any;
  footer: any;
}

const Popup: React.FC<popupProps> = (props) => {
  return (
    <div className="popup-container">
      <div className={"popup"}>
        {props.header}
        {props.content}
        {props.footer}
      </div>
    </div>
  );
};

export default Popup;
