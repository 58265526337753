import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { resetPassword, resetResetPassword } from "../store/actions";
import { Popup } from "../../dashboard/component";
import { today } from "../../../utils/datatype/date";

const ResetPasswordModal = (props:any) => {

    const dispatch = useDispatch();

    const resetPasswordData = useSelector((state: any) => state.resetPassword);

    const [oldPassword, setOldPassword] = useState({ value: "", error: false });
    const [newPassword, setNewPassword] = useState({ value: "", error: false });
    const [confirmPassword, setConfirmPassword] = useState({ value: "", error: false });

    const [token, setToken] = useState("");

    useEffect(() => {
        if(props.token !== "" && props.token !== token){
            console.log ("TOKEN", token, props.token)
            setToken(props.token);
        }
    }, [props]);

    useEffect(() => {
        if (resetPasswordData.isSuccess && resetPasswordData.data !== null) {
            toast.success("Password reset successfully")
            
            props.closeModal();
            dispatch(resetResetPassword())
        } else if (resetPasswordData.isError) {
            toast.error(resetPasswordData.error)
            dispatch(resetResetPassword())
        }



    }, [resetPasswordData])

    const __submitPassword = (e: any) => {
        e.preventDefault();

        try {
            if (oldPassword.value === "" || newPassword.value === "" || confirmPassword.value === "") {
                toast.error("All fields are required")
                return
            } else {
                dispatch(resetPassword({
                    "token": token,
                    "oldPassword": oldPassword.value,
                    "newPassword": newPassword.value
                }))
            }
        }catch (e) {
            toast.error("Something went wrong")
        }
        
    }

    const __renderResetPasswordTitle = () => {
        return (<div className="popup-title">
            <h3 className="title">Reset Password</h3>
        </div>)
    }


    // function to render the fields for the popup of passcode validation
    const __renderResetPassword = () => {
        return (
            <div className="passcode-form-wrap p-2">
                <form onSubmit={(e) => __submitPassword(e)}>
                    <div className="filter-fields p-3">
                        <h3 className="title">Old Password</h3>
                        <input type="password" name={"oldPassword"} id={"oldPassword"} onChange={(e) => setOldPassword({ value: e.target.value, error: e.target.value === "" })} />
                        {oldPassword.error ? <p className="text-danger">Old Password is required</p> : null}
                    </div>
                    <div className="filter-fields p-3">
                        <h3 className="title">New Password</h3>
                        <input type="password" name={"newPassword"} id={"newPassword"} onChange={(e) => setNewPassword({ value: e.target.value, error: e.target.value === "" })} />
                        {newPassword.error ? <p className="text-danger">New Password is required</p> : null}
                    </div>
                    <div className="filter-fields p-3">
                        <h3 className="title">Confirm Password</h3>
                        <input type="password" name={"confirmPassword"} id={"confirmPassword"} onChange={(e) => setConfirmPassword({ value: e.target.value, error: e.target.value === "" })} />
                        {confirmPassword.error ? <p className="text-danger">Confirm Password is required</p> :
                            newPassword.value !== confirmPassword.value ? <p className="text-danger">New Password and Confirm Password does not match</p> : null
                        }
                    </div>

                    <div className="p-3">
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>
        );
    };


    return (<div className="login-popup">
        <Popup
            header={__renderResetPasswordTitle()}
            content={__renderResetPassword()}
            footer={null}
        />
    </div>)
}

export default ResetPasswordModal;