import { useEffect, useState } from "react";

// Import necessary modules from react-router-dom library
import { RouterProvider } from "react-router-dom";

// import the configuration for loader
import ContentLoader from "react-content-loader";

// Import view components for the routes
import { useLegacySupport } from "../components";

import AuthStack from "./AuthenticationStack";
import DashboardStack from "./DashboardStack";
import SummaryOneViewStack from "./SummaryOneViewStack";
import { useSelector } from "react-redux";
import { xanturaLogo } from "../assets/icons";
import { LoginLoader } from "../views/dashboard/component";

// Create a browser router and define routes
const AppNavigation = () => {
  // Custom hook to check if the request is coming from summary one view or not
  const legacySupported = useLegacySupport();

  const uiStates: any = useSelector<any>((state) => state.uiStates);

  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  useEffect(() => {
    if (!legacySupported.isSummaryOneView) {
      if (legacySupported.token !== null) {
        setIsLogin(true);
        setIsFetching(false);
      } else {
        setIsFetching(false);
        // check if user has already logged in using Azure
        // initializeMSAL()
      }
    } else {
      setIsFetching(false);
    }
  }, [legacySupported]);

  useEffect(() => {
    if (uiStates.tokenData.token !== "") {
      setIsLogin(true);
    }
  }, [uiStates]);

  if (isFetching) {
    return (
      <div>
        <div
          className="loader"
          style={
            {
              // position: "absolute",
              // top: "50%",
              // left: "50%",
              // transform: "translate(-50%, -50%)",
              // marginLeft: -16,
              // marginTop: -16,
            }
          }
        >
          <div className="login-container">
            <div className="image-container">
              <img src={xanturaLogo} alt="Xantura Logo" />
            </div>
            <LoginLoader />
          </div>
        </div>
      </div>
    );
  }

  // Return the router provider component
  return (
    <RouterProvider
      router={
        !legacySupported.isSummaryOneView
          ? !isLogin
            ? AuthStack
            : DashboardStack
          : SummaryOneViewStack
      }
    />
  );
};

export default AppNavigation;
