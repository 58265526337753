// Note: Auth MSAL Types
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_RECEIVE = "LOGIN_RECEIVE";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const RESET_LOGIN = "RESET_LOGIN";

// Note: Auth MSAL Types
export const PASS_CODE_REQUEST = "PASS_CODE_REQUEST";
export const PASS_CODE_RECEIVE = "PASS_CODE_RECEIVE";
export const PASS_CODE_FAILURE = "PASS_CODE_FAILURE";
export const RESET_PASS_CODE = "RESET_PASS_CODE";


// Note: Auth MSAL Types
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_RECEIVE = "RESET_PASSWORD_RECEIVE";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const RESET_RESET_PASSWORD = "RESET_RESET_PASSWORD";


// Note: Auth MSAL Types
export const RESET_PASSCODE_REQUEST = "RESET_PASSCODE_REQUEST";
export const RESET_PASSCODE_RECEIVE = "RESET_PASSCODE_RECEIVE";
export const RESET_PASSCODE_FAILURE = "RESET_PASSCODE_FAILURE";
export const RESET_RESET_PASSCODE = "RESET_RESET_PASSCODE";