// Import React hooks and Kendo Grid components
import { Grid, GridCellProps, GridColumn, GridEvent, GridItemChangeEvent, GridPageChangeEvent, GridToolbar } from "@progress/kendo-react-grid"
import { useEffect, useState } from "react";
import { parseDataByType } from "../../../utils";
import { closeIcon, contextmenu } from "../../../assets/icons";
import { GROUP_REF_SELETECTED, TOKEN, getData } from "../../../utils/Storage";
import React from "react";
import { fetchFeedbackCSEditRequest, fetchFeedbackCSRequest, resetFeedbackCSEdit } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";


const GridContext = React.createContext<any>({});

// Interface to type-check pagination states
interface PageState {
    skip: number,
    take: number
}

const DATA_ITEM_KEY: string = "FeedbackId";
const SELECTED_FIELD: string = "SELECTED_FIELD";
const EDITFIELD: string = "inEdit";

// Initial pagination state
const initialDataState: PageState = { skip: 0, take: 10 };

/**
 * KendoGrid component to handle displaying grid data.
 *
 * @param {any} props - The props passed from the parent component.
 * @returns {JSX.Element} - The rendered component.
 */

const editField = 'inEdit';

const KendoGrid: any = (props: any) => {
    const minVisibleColumnCount = 6;

    const dispatch = useDispatch()
    const feedbackCsEdit: any = useSelector<any>((state) => state.feedbackCsEdit);
    const { t } = useTranslation();
    // State management

    const dspId = props.dspId;
    const caseSummaryType = props.caseSummaryType;

    const [gridDataOld, setGridDataOld] = useState<any>(null);
    const [gridData, setGridData] = useState<any>(null);
    const [showCancelButton, setShowCancelButton] = useState(false)
    const [sectionNamelist, setSectionNameList] = useState([])
    const [section_List, setSection_List] = useState(props.sectionPickList)

    const [groupRefID, setGroupRefID] = useState('')
    const [isPagginated, setIsPaginated] = useState<any>(null);
    const [scrollable, setScrollable] = useState<any>(initialDataState.take > 10 ? "scrollable" : "none");
    const [isHiddenColumn, setIsHiddenColumn] = useState<boolean>(false);
    const [hidenColumnCount, setHidenColumnCount] = useState<number>(0);
    const [toggleDropDown, setToggleDropDown] = useState<boolean>(false);
    const [toggleDropDownXY, setToggleDropDownXY] = useState<any>({
        x: 0, y: 0
    });
    const [token, setToken] = useState<any>({ isLegacy: false, token: "" });
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<any>(null);
    const [page, setPage] = useState<PageState>(initialDataState);
    const [pageSize, setPageSize] = useState<number>(10);
    const [sectionId, setSectionId] = useState<any>('')
    // Event handler for pagination change
    const pageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
        event.page.take > 10 ? setScrollable("scrollable") : setScrollable("none");
    }
    const getItems = () => {
        return gridDataOld;
    };

    useEffect(() => {
        getData(GROUP_REF_SELETECTED).then((res: any) => {
            if (res !== null) {
                setGroupRefID(res)
            }
        })
    }, [])



    const generateId = (data: any) =>
        gridData.reduce((acc: any, current: any) => Math.max(acc, current.FeedbackId), 0) + 1;

    const insertItem = (item: any) => {
        item.FeedbackId = generateId(gridData);
        item.inEdit = false;
        gridData.unshift(item);
        return gridData;
    };

    const EditfeedbackCsApiCall = (item: any) => {
        getData(GROUP_REF_SELETECTED).then((grouprefID_res: any) => {
            if (grouprefID_res !== null) {
                getData(TOKEN).then((res: any) => {
                    let token;
                    if (res?.provider === "msal") {
                        token = { isLegacy: false, token: res.token }

                    } else {
                        token = { isLegacy: true, token: res.token };
                    }
                    setToken({ token: token.token });
                    let body = {
                        feedbackId: item.FeedbackId,
                        dspId: dspId,
                        caseSummaryType: caseSummaryType,
                        sectionId: item.SectionId,
                        oneViewReference: grouprefID_res,
                        notes: item.Notes,

                    };
                    dispatch(fetchFeedbackCSEditRequest({ token: token, params: body }));
                })
            }
        })
    }

    const feedbackCsApiCall = (token: any) => {
        let body = {
            token: token,
            caseSummaryType: caseSummaryType,
            dspId: dspId,
            oneViewReference: groupRefID
        };
        dispatch(fetchFeedbackCSRequest({ token: token, params: body }));
    }

    useEffect(() => {
        if (
            feedbackCsEdit.isSuccess &&
            feedbackCsEdit.data !== null
        ) {
            feedbackCsApiCall(token)
            dispatch(resetFeedbackCSEdit());

        } else if (feedbackCsEdit.isError) {
            toast.error(
                feedbackCsEdit.data.error.data.title ||
                feedbackCsEdit.data.error.status
            );
            dispatch(resetFeedbackCSEdit());
        }
    }, [feedbackCsEdit]);


    const updateItem = (item: any) => {
        EditfeedbackCsApiCall(item)
        let index = gridData.findIndex((record: any) => record.FeedbackId === item.FeedbackId);
        gridData[index] = item;
        return gridData;
    };

    const deleteItem = (item: any) => {
        let index = gridData.findIndex((record: any) => record.FeedbackId === item.FeedbackId);
        gridData.splice(index, 1);

        return gridData;
    };

    const remove = (dataItem: any) => {
        const newData = deleteItem(dataItem);
        setGridData([...newData]);
    };

    const add = (dataItem: any) => {
        dataItem.inEdit = true;
        const newData = insertItem(dataItem);
        setGridData(newData);
    };

    const removeGrid = (dataItem: any) => {
        props.customAction({ dataItem, action: "delete" })
    }

    // Data processing useEffect
    useEffect(() => {
        if (props.caseData.gridRows.length > 0) {
            if (props.caseData.gridColumns.length > minVisibleColumnCount) {
                setIsHiddenColumn(true)
                setHidenColumnCount(props.caseData.gridColumns.length - minVisibleColumnCount)
            }
        }

        let list = props.caseData.gridColumns.filter((item: any) => {
            if (item.dataType === 'picklist')
                return item.pickListValues
        })
        let newData = list[0].pickListValues.map((item: any) =>
            item ? { text: item.textToDisplay, value: item.valueToStore } : item
        );
        setSectionNameList(newData)
        __processData()

    }, [props]);


    useEffect(() => {
        __processData()
    }, [isHiddenColumn])

    // Function to process grid data
    const __processData = () => {
        try {
            let processedData: any = [];
            // set page size based on props
            setPageSize(props.pageSize !== "" ? props.pageSize : 10)
            if (props.caseData.gridRows.length > 0) {
                props.caseData.gridRows.forEach((res: any, index: number) => {
                    let obj: any = {};
                    res.values.forEach((tdRes: any, tdIndex: number) => {
                        const colDetails = props.caseData.gridColumns[tdIndex];
                        if (isHiddenColumn) {
                            if (tdIndex < minVisibleColumnCount) {
                                obj[colDetails.name.trim()] = parseDataByType(tdRes, colDetails.dataType);
                            }
                        } else {
                            obj[colDetails.name.trim()] = parseDataByType(tdRes, colDetails.dataType);
                        }
                    });
                    processedData.push(obj);
                })
                setGridDataOld(processedData)
                setGridData(processedData)
                if (page.skip == processedData.length) {
                    setPage({ skip: page.skip - 10, take: 10 })
                }
                if (processedData.length > 10) {
                    setIsPaginated(true)
                }
                else {
                    setIsPaginated(false)
                }
            } else {
                setGridData(null)
            }
        } catch (e) {
            console.log(e)
        }
    }

    // Handling scroll event to fetch more data
    const scrollHandler = (event: GridEvent) => {
        const e = event.nativeEvent;
        if (
            e.target.scrollTop + 10 >=
            e.target.scrollHeight - e.target.clientHeight
        ) {
            const moreData = gridData.splice(0, 10);
            if (moreData.length > 0) {
                setGridData((oldData: any) => oldData.concat(moreData));
            }
        }
    };

    const __renderDropdown = () => {
        const inEdit = selectedRecord?.inEdit ? true : false;
        const isNewItem = selectedRecord.FeedbackId === undefined;
        return toggleDropDown ?
            <><div className="" style={{ position: "fixed", top: (toggleDropDownXY.y + 10), left: (toggleDropDownXY.x - 50), zIndex: 999 }}>
                <ul className={`dropdown-menu ${toggleDropDown ? "show" : "hide"}`}>
                    <li><button className="btn btn-sm btn-block" onClick={
                        () => {
                            setToggleDropDown(false)
                            inEdit
                                ? isNewItem
                                    ? add(selectedRecord)
                                    : update(selectedRecord)
                                : enterEdit(selectedRecord)
                        }
                    }>{inEdit ? (isNewItem ? 'Add' : t('UPDATE')) : 'Edit'}</button></li>
                    <li><button className="btn btn-sm btn-block"
                        style={{ color: confirmDelete ? '#ffffff' : '#5464B0', backgroundColor: confirmDelete ? '#FF0000' : '#ffffff' }} onClick={() => {
                            if (confirmDelete) {
                                setToggleDropDown(false)
                                removeGrid(selectedRecord)
                            } else {
                                // Change the button text to "Confirm"
                                setConfirmDelete(true);
                            }

                        }}>{confirmDelete ? 'Confirm' : 'Delete'}</button></li>
                </ul>
            </div >
                <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(255,255,255,0)", zIndex: 889 }}
                    onClick={(e: any) => {
                        setToggleDropDown(false)
                    }}>

                </div>
            </> : null
    }

    const __renderGridToolbar = () => {
        if (!hidenColumnCount) {
            return null
        }

        return <GridToolbar>
            {/* <div className="export-btns-container">
            <button className="btn btn-primary btn-sm btn-inline-block" onClick={() => exportExcel()}>Export to Excel</button>
        </div> */}

            {/* <div className="pr-1 d-inline-block"></div>
        <button className="btn btn-primary btn-sm btn-inline-block" onClick={() => { }}>Select</button> */}
            <div className="pr-1 d-inline-block"></div>

            <button className="btn btn-primary btn-sm btn-inline-block" onClick={() => {
                setIsHiddenColumn(!isHiddenColumn);
            }}>{isHiddenColumn ? "+" : "-"} {hidenColumnCount}</button>
        </GridToolbar>
    }


    const _customActions = (props: GridCellProps) => {
        const { enterEdit, remove, add, discard, update, cancel, editField } =
            React.useContext(GridContext);
        const { dataItem } = props;
        const inEdit = dataItem[editField];
        const isNewItem = dataItem.FeedbackId === undefined;
        return (
            <>
                <td>
                    <div className="d-flex">
                        <button className="btn btn-sm" onClick={(e) => {
                            if (!showCancelButton) {
                                setConfirmDelete(false);
                                setToggleDropDownXY({ x: e.clientX, y: e.clientY })
                                setToggleDropDown(!toggleDropDown)
                                setSelectedRecord(props.dataItem)
                            }
                        }}><img src={contextmenu} style={{ width: 20 }} /></button>
                        {showCancelButton && selectedRecord !== null && inEdit &&
                            <>
                                <button
                                    className="btn btn-sm btn-primary"
                                    onClick={() => {
                                        setShowCancelButton(false)
                                        isNewItem ? add(dataItem) : update(dataItem)
                                    }
                                    }
                                >
                                    {t('UPDATE')}
                                </button>
                                <button
                                    className="btn btn-sm btn-danger mx-2"
                                    onClick={() => {
                                        setShowCancelButton(false)
                                        isNewItem ? discard(dataItem) : cancel(dataItem)
                                    }}
                                >
                                    <img style={{ width: 14, height: 14 }} src={closeIcon} alt="Close icon" />
                                </button>
                            </>}
                    </div>
                </td >
            </>);
    }

    // No data, returning empty message
    if (gridData === null) {
        return <div className="p-2">{props.caseData.emptyMessage}</div>
    }

    const itemChange = (event: GridItemChangeEvent) => {
        const field = event.field || '';
        const newData = gridData.map((item: any) =>
            item.FeedbackId === event.dataItem.FeedbackId
                ? { ...item, [field]: event.value }
                : item
        );

        setGridData(newData);
    };


    const __renderColumns = () => {
        const columns: any = []
        columns.push(<GridColumn cell={_customActions} filterable={false} title="Actions" width={100} />)
        props?.caseData?.gridColumns.map((tdCol: any, index: number) => {

            if (tdCol.isVisible) {
                let _props = { DropDownCell }
                // optimization for hidden columns
                const grid = <GridColumn
                    {..._props}
                    editable={tdCol.name == 'Notes' && "SectionName" ? true : false}
                    // editable={tdCol.isVisible}
                    editor="text"
                    key={index}
                    cell={tdCol.name == "SectionName" ? DropDownCell : undefined}
                    field={tdCol.name.trim()}
                    title={tdCol.displayName === "" ? tdCol.name : tdCol.displayName}
                    width={tdCol.width === 0 ? "auto" : tdCol.width}

                />
                if (isHiddenColumn) {
                    if (index < minVisibleColumnCount) {
                        columns.push(grid)
                    }
                } else {
                    columns.push(grid)
                }
            }
        }
        )
        return columns
    }

    const discard = (dataItem: any) => {
        const newData = [...gridData];
        newData.splice(0, 1);
        setGridData(newData);
    };


    const DropDownCell = (props: GridCellProps) => {



        const { dataItem } = props;
        const field = props.field || '';
        const dataValue = dataItem[field] === null ? '' : dataItem[field];
        
        return (
            <td>
                {dataItem.inEdit ? (
                    <select
                        value={dataItem.SectionId || ""}
                        // className="involve-case"
                        onChange={(e) => {
                            const id = section_List.find(
                                (options: any, index: any) => {
                                    return e.target.value === options.value;
                                }
                            );
                            console.log('ididid:', id)
                            setSectionId(id.value);
                            dataItem.SectionId = id.value
                            dataItem.SectionName = id.text
                            enterEdit(dataItem)
                        }}
                    >
                        {section_List.map((options: any, index: any) => {
                            return (
                                <option value={options.value} key={options.value}>
                                    {options.text}
                                </option>
                            );
                        })}
                    </select>
                    // <DropDownList
                    //     style={{ width: '100px' }}
                    //     onChange={handleChange}
                    //     value={localizedData.find((c) => c.value === dataValue)}
                    //     data={localizedData}
                    //     textField="text"
                    // />
                ) : dataValue == null ? (
                    ''
                ) : dataValue ? (
                    dataItem.SectionName
                ) : (
                    sectionId
                )}
            </td>
        );
    }

    const update = (dataItem: any) => {
        dataItem.inEdit = false;
        const newData = updateItem(dataItem);
        setGridData(newData);
    };

    const cancel = (dataItem: any) => {
        dataItem.inEdit = false;
        const originalItem: any = getItems().find(
            (p: any) => p.FeedbackId === dataItem.FeedbackId
        );
        const newData = gridData.map((item: any) =>
            item.FeedbackId === originalItem.FeedbackId ? originalItem : item
        );
        setGridData(newData);
    };


    const enterEdit = (dataItem: any) => {
        setShowCancelButton(true)
        let newData = gridData.map((item: any) =>
            item.FeedbackId === dataItem.FeedbackId ? { ...item, inEdit: true } : item
        );
        setGridData(newData);
    };

    // Main component render
    return (
        <div>
            {toggleDropDown &&
                __renderDropdown()}
            {__renderGridToolbar()}
            <GridContext.Provider
                value={{ enterEdit, remove, add, discard, update, cancel, editField }}
            >
                <Grid
                    data={gridData.slice(page.skip, page.take + page.skip)}
                    columnVirtualization={false}
                    editField={editField}
                    onItemChange={itemChange}
                    style={{ minHeight: '400px', overflowX: "scroll" }}
                    scrollable={scrollable}

                    sortable={true}
                    skip={page.skip}
                    take={page.take}
                    total={gridData.length}
                    onPageChange={pageChange}
                    pageSize={pageSize}

                    onScroll={scrollHandler}
                    fixedScroll={true}

                    pageable={isPagginated ? {
                        buttonCount: 4,
                        pageSizes: false
                    } : false}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                >
                    {__renderColumns()}
                    {/* <GridColumn field="Discontinued" title="sssDiscontinued" cell={DropDownCell} /> */}

                </Grid>
            </GridContext.Provider>
        </div>
    )
}

export default KendoGrid;