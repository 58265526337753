import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

export default function configureReduxStore() {
    const store: any = configureStore({ reducer: rootReducer, middleware: [sagaMiddleware]})    
    sagaMiddleware.run(rootSaga);

    return store;
}