import { createSlice } from '@reduxjs/toolkit';
import { setInitialState } from "../../../store/InitialState";

const INITIAL_STATE = setInitialState({
    data: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    error: null,
});

const bulkConfigActionSlice = createSlice({
    name: 'bulkConfigActionSlice',
    initialState: INITIAL_STATE,
    reducers: {
        bulkConfigActionRequest: (state) => {
            state.isFetching = true; },
        bulkConfigActionSuccess: (state, action) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.data = action.payload;
        },
        bulkConfigActionFailuer: (state, action) => {
            state.isFetching = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },
        resetBulkConfigAction: (state) => {
            state.data = null;
            state.error = null;
            state.isError = false;
            state.isFetching = false;
            state.isSuccess = false;
        }
    }
});

// Exporting actions for saga and views 
export const { bulkConfigActionRequest, bulkConfigActionSuccess, bulkConfigActionFailuer, resetBulkConfigAction } = bulkConfigActionSlice.actions;

// const selectLogin = (state: any) => state.bulkConfigActionSlice;

// export const selectLoginData = createSelector(selectLogin, (login) => {
//     console.log('login', login);
//     return login.data;
// });

// Exporting bulkConfigActionSlice reducer for combine reducer 
export default bulkConfigActionSlice.reducer;