// Types for case summary config
export const CASE_SUMMARY_CONFIG_REQUEST = "CASE_SUMMARY_CONFIG_REQUEST";
export const CASE_SUMMARY_CONFIG_RECEIVE = "CASE_SUMMARY_CONFIG_RECEIVE";
export const CASE_SUMMARY_CONFIG_FAILURE = "CASE_SUMMARY_CONFIG_FAILURE";
export const RESET_CASE_SUMMARY_CONFIG = "RESET_CASE_SUMMARY_CONFIG";

// Types for case summary data
export const CASE_SUMMARY_DATA_REQUEST = "CASE_SUMMARY_DATA_REQUEST";
export const CASE_SUMMARY_DATA_RECEIVE = "CASE_SUMMARY_DATA_RECEIVE";
export const CASE_SUMMARY_DATA_FAILURE = "CASE_SUMMARY_DATA_FAILURE";
export const RESET_CASE_SUMMARY_DATA = "RESET_CASE_SUMMARY_DATA";

// Types for case data store in redux
export const CASE_DATA_UPDATE = "CASE_DATA_UPDATE";
export const RESET_CASE_DATA = "RESET_CASE_DATA";

// Types for information governance form data
export const INFORMATION_GOVERNANCE_DATA_REQUEST = "INFORMATION_GOVERNANCE_DATA_REQUEST";
export const INFORMATION_GOVERNANCE_DATA_RECEIVE = "INFORMATION_GOVERNANCE_DATA_RECEIVE";
export const INFORMATION_GOVERNANCE_DATA_FAILURE = "INFORMATION_GOVERNANCE_DATA_FAILURE";
export const RESET_INFORMATION_GOVERNANCE_DATA = "RESET_INFORMATION_GOVERNANCE_DATA";

// Types for information governance access request
export const IGAPI_DATA_REQUEST = "IGAPI_DATA_REQUEST";
export const IGAPI_DATA_RECEIVE = "IGAPI_DATA_RECEIVE";
export const IGAPI_DATA_FAILURE = "IGAPI_DATA_FAILURE";
export const RESET_IGAPI_DATA = "RESET_IGAPI_DATA";

// Types for fetch overview reference
export const FETCH_OVERVIEWREFERENCE_REQUEST = "FETCH_OVERVIEWREFERENCE_REQUEST";
export const FETCH_OVERVIEWREFERENCE_RECEIVE = "FETCH_OVERVIEWREFERENCE_RECEIVE";
export const FETCH_OVERVIEWREFERENCE_FAILURE = "FETCH_OVERVIEWREFERENCE_FAILURE";
export const RESET_FETCH_OVERVIEWREFERENCE = "RESET_FETCH_OVERVIEWREFERENCE";

// Types for fetch overview reference
export const FETCH_OVERVIEWREFERENCE_ACCESS_REQUEST = "FETCH_OVERVIEWREFERENCE_ACCESS_REQUEST";
export const FETCH_OVERVIEWREFERENCE_ACCESS_RECEIVE = "FETCH_OVERVIEWREFERENCE_ACCESS_RECEIVE";
export const FETCH_OVERVIEWREFERENCE_ACCESS_FAILURE = "FETCH_OVERVIEWREFERENCE_ACCESS_FAILURE";
export const RESET_FETCH_OVERVIEWREFERENCE_ACCESS = "RESET_FETCH_OVERVIEWREFERENCE_ACCESS";



//Types for cs-feedback-columns
export const FEEDBACK_CS_COLUMNS_REQUEST = "FEEDBACK_CS_COLUMNS_REQUEST";
export const FEEDBACK_CS_COLUMNS_RECEIVE = "FEEDBACK_CS_COLUMNS_RECEIVE";
export const FEEDBACK_CS_COLUMNS_FAILURE = "FEEDBACK_CS_COLUMNS_FAILURE";
export const RESET_FEEDBACK_CS_COLUMNS = "RESET_FEEDBACK_CS_COLUMNS";

//Types for cs-feedback 
export const FEEDBACK_CS_REQUEST = "FEEDBACK_CS_REQUEST";
export const FEEDBACK_CS_RECEIVE = "FEEDBACK_CS_RECEIVE";
export const FEEDBACK_CS_FAILURE = "FEEDBACK_CS_FAILURE";
export const RESET_FEEDBACK_CS = "RESET_FEEDBACK_CS";

//Types for cs-feedback-add
export const FEEDBACK_CS_ADD_REQUEST = "FEEDBACK_CS_ADD_REQUEST";
export const FEEDBACK_CS_ADD_RECEIVE = "FEEDBACK_CS_ADD_RECEIVE";
export const FEEDBACK_CS_ADD_FAILURE = "FEEDBACK_CS_ADD_FAILURE";
export const RESET_FEEDBACK_CS_ADD = "RESET_FEEDBACK_CS_ADD";

//Types for cs-feedback-edit
export const FEEDBACK_CS_EDIT_REQUEST = "FEEDBACK_CS_EDIT_REQUEST";
export const FEEDBACK_CS_EDIT_RECEIVE = "FEEDBACK_CS_EDIT_RECEIVE";
export const FEEDBACK_CS_EDIT_FAILURE = "FEEDBACK_CS_EDIT_FAILURE";
export const RESET_FEEDBACK_CS_EDIT = "RESET_FEEDBACK_CS_EDIT";


//Types for cs-feedback-delete
export const FEEDBACK_CS_DELETE_REQUEST = "FEEDBACK_CS_DELETE_REQUEST";
export const FEEDBACK_CS_DELETE_RECEIVE = "FEEDBACK_CS_DELETE_RECEIVE";
export const FEEDBACK_CS_DELETE_FAILURE = "FEEDBACK_CS_DELETE_FAILURE";
export const RESET_FEEDBACK_CS_DELETE = "RESET_FEEDBACK_CS_DELETE";