// Import the types to be used for dispatching actions
import * as type from "../types";

/**
 * Dispatches an action to fetch the Case Summary Configuration.
 *
 * @param {any} params - Parameters needed for fetching Case Summary Configuration.
 * @returns {object} - The action object.
 */
export const preparePDF = (params: boolean) => {
    return { type: type.IS_PRINTABLE, payload: params }
}

export const isLogin = (params: any) => {
    return { type: type.IS_LOGIN, payload: params }
}


export const setSelectedDSPId = (params: any) => {
    return { type: type.SETDSP, payload: params }
}