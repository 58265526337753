// Importing action types from the types file
import * as types from "../types";

// Setting the initial state for this reducer
const INITIAL_STATE = { isPrintable: false, tokenData: { token: '', isLegacy: false, provider: '' },
selected_dsp_id: {updated: false, dsp: ''} };

/**
 * A Redux reducer that handles actions related to case data.
 * 
 * @param {object} state - The current state of the application.
 * @param {object} action - An action dispatched from the application.
 * @returns {object} - The new state of the application.
 */
export default function (state = INITIAL_STATE, action: any) {
    // Using a switch statement to handle different action types
    switch (action.type) {
        case types.IS_PRINTABLE:
            
            // Return the new state with updated data
            return {
                ...state,
                isPrintable: action.payload
            };
        case types.IS_LOGIN:

            // Return the new state with updated data
            return {
                ...state,
                tokenData: action.payload
            };
            
        case types.SETDSP:

            // Return the new state with updated data
            return {
                ...state,
                selected_dsp_id: action.payload
            };
        default:
            // Return the existing state if action type doesn't match
            return {
                ...state
            };
    }
}