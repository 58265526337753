// Import the React library for building user interfaces
import React from 'react';

// Import the ReactDOM library for rendering React components in the DOM
import ReactDOM from 'react-dom/client';

// Import the Provider component from react-redux for connecting React components to a Redux store
import { Provider } from 'react-redux';

// Import the function to configure the Redux store
import configureReduxStore from './store';

// Import the root App component
import App from './App';

// Import the function to report web vitals for performance measurement
import reportWebVitals from './reportWebVitals';

// Import the main router object for the application
import AppNavigation from './navigator/AppNavigation';

// Import global SCSS styles for the application
import './assets/css/scss/app.scss';

// Import the default theme for Kendo UI components
// import '@progress/kendo-theme-default/dist/all.css';



// Import styles for React Toastify notifications
import 'react-toastify/dist/ReactToastify.css';

// Create a root container for React components using ReactDOM.createRoot
const root = ReactDOM.createRoot(
  document.getElementById(`${process.env.REACT_APP_HTML_ROOT}`) as HTMLElement
);

// Configure and create the Redux store
const store = configureReduxStore();

// Render the React components inside the root container
root.render(
  // <React.StrictMode> could be used here for additional checks and warnings during development
  <Provider store={store}> {/* Provide the Redux store to React components */}
    <App> {/* The root App component */}
      <AppNavigation /> {/* The main router object for the application */}
    </App>
  </Provider>
  // </React.StrictMode>
);

// Record and log web vitals for performance analysis
// Learn more about measuring web vitals: https://bit.ly/CRA-vitals
reportWebVitals();