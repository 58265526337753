// TODO: rename to notification.slice.ts

import { createSelector, createSlice } from '@reduxjs/toolkit';
import { setInitialState } from "../../../store/InitialState";

const INITIAL_STATE = setInitialState({
    data: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    error: null,
});

const bulkActionSlice = createSlice({
    name: 'bulkActionSlice',
    initialState: INITIAL_STATE,
    reducers: {
        bulkActionRequest: (state) => {
            state.isFetching = true; },
        bulkActionSuccess: (state, action) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.data = action.payload;
        },
        bulkActionFailuer: (state, action) => {
            state.isFetching = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },
        resetBulkAction: (state) => {
            state.data = null;
            state.error = null;
            state.isError = false;
            state.isFetching = false;
            state.isSuccess = false;
        }
    }
});

// Exporting actions for saga and views 
export const { bulkActionRequest, bulkActionSuccess, bulkActionFailuer, resetBulkAction } = bulkActionSlice.actions;

// const selectLogin = (state: any) => state.bulkActionSlice;

// export const selectLoginData = createSelector(selectLogin, (login) => {
//     console.log('login', login);
//     return login.data;
// });

// Exporting bulkActionSlice reducer for combine reducer 
export default bulkActionSlice.reducer;