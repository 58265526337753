// import format data by type
import { parseDataByType } from "../../../utils"

/**
 * List component to render rows of data in a table-like structure.
 *
 * @param {any} props - The props object containing data and other optional settings.
 * @returns {JSX.Element} - The rendered List component.
 */
const List: any = (props: any) => {

/**
 * Helper function to render individual rows of data.
 *
 * @param {any} data - The data for a single row.
 * @param {number} index - The index of the row within the larger data array.
 * @returns {JSX.Element[]} - Array of JSX elements representing table rows.
 */
    const __renderDataRows = (data: any, index: number) => {
        return data.values.map((tdData: any, key: number) => {

            const column = props.caseData.caseSummaryDataColumns[key];

            return <tr key={index + "_" + key} >
                <td style={{ ...column.columnStyle, width: column.width === 0 ? "auto" : column.width }}><p>{column?.displayName !== '' ? column?.displayName : column.name}</p></td>
                <td><p>{parseDataByType(tdData, column.dataType)}</p></td></tr>
        })
    }

    /**
       * Helper function to render the body of the table.
       *
       * @param {any} data - The array of data rows to display.
       * @param {string} emptyMessage - The message to display when there is no data.
       * @returns {JSX.Element} - JSX elements wrapping table rows or an empty message.
       */
    const __renderTableBody = (data: any, emptyMessage: string) => {
        if (data.length === 0) {
            return <div><p>{emptyMessage}</p></div>
        }
        return data.map((res: any, index: number) => {
            return <div className="table-wrapper" key={index}><table className="table-list"><tbody>{__renderDataRows(res, index)}</tbody></table></div>
        })
    }

    // Check if data is null, and if so, render an "Unavailable" message
    if (props.caseData === null) {
        return <div className="p-2">Unavailable</div>
    }

    // Main component render logic
    return (__renderTableBody(props.caseData.caseSummaryDataRows, props.caseData.emptyMessage))
}

// Export the List component
export default List;