export const COMMUNITY_ASSETS_CONFIG_REQUEST = "COMMUNITY_ASSETS_CONFIG_REQUEST";
export const COMMUNITY_ASSETS_CONFIG_RECEIVE = "COMMUNITY_ASSETS_CONFIG_RECEIVE";
export const COMMUNITY_ASSETS_CONFIG_FAILURE = "COMMUNITY_ASSETS_CONFIG_FAILURE";
export const RESET_COMMUNITY_ASSETS_CONFIG = "RESET_COMMUNITY_ASSETS_CONFIG";


export const COMMUNITY_ASSETS_LIST_REQUEST = "COMMUNITY_ASSETS_LIST_REQUEST";
export const COMMUNITY_ASSETS_LIST_RECEIVE = "COMMUNITY_ASSETS_LIST_RECEIVE";
export const COMMUNITY_ASSETS_LIST_FAILURE = "COMMUNITY_ASSETS_LIST_FAILURE";
export const RESET_COMMUNITY_ASSETS_LIST = "RESET_COMMUNITY_ASSETS_LIST";

// ------------Types of map API---------------------
export const COMMUNITY_ASSETS_REQUEST = "COMMUNITY_ASSETS_REQUEST";
export const COMMUNITY_ASSETS_RECEIVE = "COMMUNITY_ASSETS_RECEIVE";
export const COMMUNITY_ASSETS_FAILURE = "COMMUNITY_ASSETS_FAILURE";
export const RESET_COMMUNITY_ASSETS = "RESET_COMMUNITY_ASSETS";

export const MAKE_SHIFT_REQUEST = "MAKE_SHIFT_REQUEST";
export const MAKE_SHIFT_RECEIVE = "MAKE_SHIFT_RECEIVE";
export const MAKE_SHIFT_FAILURE = "MAKE_SHIFT_FAILURE";
export const RESET_MAKE_SHIFT = "RESET_MAKE_SHIFT";