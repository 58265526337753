// Import the types to be used for dispatching actions
import * as type from "./types";

/**
 * Dispatches an action to fetch the Case Summary Configuration.
 *
 * @param {any} params - Parameters needed for fetching Case Summary Configuration.
 * @returns {object} - The action object.
 */
export const fetchCaseSummaryConfig = (params: any) => {
    return { type: type.CASE_SUMMARY_CONFIG_REQUEST, payload: params }
}

/**
 * Resets the state of the Case Summary Configuration.
 *
 * @returns {object} - The action object.
 */
export const resetCaseSummaryConfig = () => {
    return { type: type.RESET_CASE_SUMMARY_CONFIG, payload: null }
}

/**
 * Dispatches an action to fetch Overview Reference data.
 *
 * @param {any} params - Parameters needed for fetching Overview Reference.
 * @returns {object} - The action object.
 */
export const fetchOverviewreference = (params: any) => {
    return { type: type.FETCH_OVERVIEWREFERENCE_REQUEST, payload: params }
}

/**
 * Resets the state of the Overview Reference.
 *
 * @returns {object} - The action object.
 */
export const resetFetchOverviewreference = () => {
    return { type: type.RESET_FETCH_OVERVIEWREFERENCE, payload: null }
}


/**
 * Dispatches an action to fetch Overview Reference data.
 *
 * @param {any} params - Parameters needed for fetching Overview Reference.
 * @returns {object} - The action object.
 */
export const fetchOverviewreferenceAccess = (params: any) => {
    return { type: type.FETCH_OVERVIEWREFERENCE_ACCESS_REQUEST, payload: params }
}

/**
 * Resets the state of the Overview Reference.
 *
 * @returns {object} - The action object.
 */
export const resetFetchOverviewreferenceAccess = () => {
    return { type: type.RESET_FETCH_OVERVIEWREFERENCE_ACCESS, payload: null }
}

/**
 * Dispatches an action to fetch Case Summary Data.
 *
 * @param {any} params - Parameters needed for fetching Case Summary Data.
 * @returns {object} - The action object.
 */
export const fetchCaseSummaryData = (params: any) => {
    return { type: type.CASE_SUMMARY_DATA_REQUEST, payload: params }
}

/**
 * Resets the state of the Case Summary Data.
 *
 * @returns {object} - The action object.
 */
export const resetCaseSummaryData = () => {
    return { type: type.RESET_CASE_SUMMARY_DATA, payload: null }
}

/**
 * Dispatches an action to update Case Data.
 *
 * @param {any} params - Parameters needed for updating Case Data.
 * @returns {object} - The action object.
 */
export const updateData = (params: any) => {
    return { type: type.CASE_DATA_UPDATE, payload: params }
}

/**
 * Dispatches an action to fetch Information Governance Data.
 *
 * @param {any} params - Parameters needed for fetching Information Governance Data.
 * @returns {object} - The action object.
 */
export const fetchInformationGovernanceData = (params: any) => {
    return { type: type.INFORMATION_GOVERNANCE_DATA_REQUEST, payload: params }
}

/**
 * Resets the state of Information Governance Data.
 *
 * @returns {object} - The action object.
 */
export const resetInformationGovernanceData = () => {
    return { type: type.RESET_INFORMATION_GOVERNANCE_DATA, payload: null }
}

/**
 * Dispatches an action to fetch IGAPI Data.
 *
 * @param {any} params - Parameters needed for fetching IGAPI Data.
 * @returns {object} - The action object.
 */
export const fetchIGData = (params: any) => {
    return { type: type.IGAPI_DATA_REQUEST, payload: params }
}

/**
 * Resets the state of the IGAPI Data.
 *
 * @returns {object} - The action object.
 */
export const resetIGData = () => {
    return { type: type.RESET_IGAPI_DATA, payload: null }
}

/**
 * Dispatches an action to fetch Feedback CS Columns.
 *
 * @param {any} params - Parameters needed for fetching Feedback CS Columns.
 * @returns {object} - The action object.
 */
export const fetchFeedbackCSDeleteRequest = (params: any) => {
    return { type: type.FEEDBACK_CS_DELETE_REQUEST, payload: params }
}


/**
 * Resets the state of the IGAPI Data.
 *
 * @returns {object} - The action object.
 */
export const resetFeedbackCSDelete = () => {
    return { type: type.RESET_FEEDBACK_CS_DELETE, payload: null }
}


/**
 * Dispatches an action to fetch Feedback CS Columns.
 *
 * @param {any} params - Parameters needed for fetching Feedback CS Columns.
 * @returns {object} - The action object.
 */
export const fetchFeedbackCSRequest = (params: any) => {
    return { type: type.FEEDBACK_CS_REQUEST, payload: params }
}


/**
 * Resets the state of feedback CS.
 *
 * @returns {object} - The action object.
 */

export const resetFeedbackCS = () => {
    return { type: type.RESET_FEEDBACK_CS, payload: null }
}





/**
 * Dispatches an action to fetch Feedback CS Columns.
 *
 * @param {any} params - Parameters needed for fetching Feedback CS Columns.
 * @returns {object} - The action object.
 */
export const fetchFeedbackCSADDRequest = (params: any) => {
    return { type: type.FEEDBACK_CS_ADD_REQUEST, payload: params }
}

/**
 * Resets the state of feedback CS add.
 *
 * @returns {object} - The action object.
 */
export const resetFeedbackCSAdd = () => {
    return { type: type.RESET_FEEDBACK_CS_ADD, payload: null }
}


/**
 * Dispatches an action to fetch Feedback CS Columns.
 *
 * @param {any} params - Parameters needed for fetching Feedback CS Columns.
 * @returns {object} - The action object.
 */
export const fetchFeedbackCSEditRequest = (params: any) => {
    return { type: type.FEEDBACK_CS_EDIT_REQUEST, payload: params }
}



/**
 * Resets the state of feedback CS edit.
 *
 * @returns {object} - The action object.
 */

export const resetFeedbackCSEdit = () => {
    return { type: type.RESET_FEEDBACK_CS_EDIT, payload: null }
}