// Import React hooks and assets
import { useState } from 'react';
import {
    chevron_down,
    adults,
    child,
    systemref,
    addr_history
} from "../../assets/icons"
import FeedbackPopup from './FeedbackPopup';
import AddFeedbackPopup from './AddFeedbackPopup';


/**
 * Wrapper Component: A container component that can expand/collapse its children.
 *
 * @param {any} props - The properties passed into the component.
 * @returns {JSX.Element} - The rendered JSX element.
 */
const Wrapper: any = (props: any) => {
    // State to manage whether the component is prepopulated or not.
    const [isOpen, setIsOpen] = useState<boolean>(props.isOpen)
    const [showFeedbackpopup, setShowFeedbackpopup] = useState<boolean>(false);

    // Define colors for the header based on props
    const activeColor = props?.headerStyle?.headerOpenColour !== "" ? props?.headerStyle?.headerOpenColour : null;
    const inactiveColor = props?.headerStyle?.headerClosedColour !== "" ? props?.headerStyle?.headerClosedColour : null;

    // Define colors for the header based on props
    const activeFontColor = props?.headerFontStyle?.headerOpenFontColour !== "" ? props?.headerFontStyle?.headerOpenFontColour : null;
    const inactiveFontColor = props?.headerFontStyle?.headerClosedFontColour !== "" ? props?.headerFontStyle?.headerClosedFontColour : null;

    /**
     * Handle click events to expand/collapse the wrapper.
     */
    const _handleClickEvent = (): void => {
        // Toggle the prepopulation state
        setIsOpen(!isOpen);

        // Call parent's onClickExpand method.
        props.onClickExpand(props.id);
    }

    /**
    * Determine which icon to display based on the given path.
    *
    * @param {string} path - The image path for the icon.
    * @returns {JSX.Element|null} - The icon or null if not found.
    */

    const getIcon = (path: string) => {
        let iconPath = path.replace("/images/", "").replace(".png", "")

        let icon = null

        switch (iconPath) {
            case "adults":
                icon = adults
                break;

            case "child":
                icon = child
                break;

            case "systemref":
                icon = systemref
                break;

            case "addr_history":
                icon = addr_history
                break;

            default:
                icon = null
        }

        return (props.isAside !== undefined && props.iconImage !== "" && icon !== null ?
            <span className='pr-3 d-inline-block'><img alt="Aside dropdown icon" src={icon} className="mr-2" style={{ width: "30px", marginRight: "10px", paddingBottom: "4px" }} /></span>
            : null)
    }

    const showfeedbackbuttoncase = (show: boolean) => {
        let showButton = show
        switch (showButton) {
            case true:
                showButton = true
                break;
            default:
                showButton = false
        }

        return (showButton && showButton !== null ?
            showFeedbackButton()
            : null)
    }

    const showFeedbackButton = () => {
        return (
            <button
                className="btn-feedback btn btn-collapse feedback-button" onClick={() => {
                    setShowFeedbackpopup(!showFeedbackpopup)
                }}>Add Feedback +</button>
        )
    }

    // Main render logic
    return <div className={`mb-3 collapsible-wpr ${isOpen ? "collapsible-open" : "collapsible-close"}`}>
        <div
            style={{ background: props.isOpen ? activeColor : !isOpen ? activeColor : inactiveColor, borderRadius: "15px" }}
            className={isOpen ? "p-2 d-flex justify-content-between title-container open-data" : "p-2 d-flex justify-content-between title-container "}>
            <div className="title">
                <h5 key={props.id} id={props.id} title={props.title}
                    style={{ color: props.isOpen ? activeFontColor : !isOpen ? activeFontColor : inactiveFontColor }}>
                    {getIcon(props.iconImage)}
                    {props.title}
                </h5>
            </div>

            <div className='btn-wrapper'>

                <button className="btn btn-sm btn-collapse" onClick={() => _handleClickEvent()}>
                    <img src={chevron_down} />
                </button>
            </div>
        </div>



        {isOpen ? (
            <div className="p-2 tab-container">
                {props.children}
            </div>
        ) : null}
    </div>
}

// Export the Wrapper component.
export default Wrapper;